import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../atoms/Button/Button';
import Modal from '../Modal/Modal';
import Carousel from '../Carousel/Carousel';
import Img from '../../atoms/Img/Img';
import defaultImg from '../../../assets/images/default-image.jpg';

import './UnitImages.scss';

const UnitImages = ({
  images,
}) => {
  const [modalState, setModalState] = useState({
    imageModal: false,
  });

  return (
    <div className="unitImages">
      { images.length === 0
      && (
      <div className="quote__image" data-testid="no-images">
        <Img alt="grainger logo" src={defaultImg} className="unitImages__default" />
      </div>
      )}
      { images.length > 0
    && (
      <>
        <Carousel small imageKeys={images} />
        <Button
          plain
          title="Enlarge"
          className="enlargeButton"
          onClick={() => setModalState({ imageModal: true })}
        />

        {modalState.imageModal && (
        <Modal modalState={modalState} modalName="imageModal" setModalState={setModalState}>
          <Carousel imageKeys={images} />
        </Modal>
        )}
      </>
    )}

    </div>
  );
};

UnitImages.propTypes = {
  images: PropTypes.instanceOf(Array).isRequired,
};

export default UnitImages;
