import React from 'react';
import PropTypes from 'prop-types';
import './Loading.scss';

const Loading = ({ fullscreen, message }) => (
  <div
    className={`loading ${fullscreen ? 'centered--fixed' : ''}`}
    data-testid="loading-status"
  >
    <h2>
      {message || 'Loading'}
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </h2>
  </div>
);

Loading.propTypes = {
  fullscreen: PropTypes.bool,
  message: PropTypes.string,
};

Loading.defaultProps = {
  fullscreen: false,
  message: null,
};

export default Loading;
