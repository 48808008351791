import React from 'react';
import PropTypes from 'prop-types';
import { numberTo2dp, formatDateFromSFDC } from '../../../helpers/utils/generic';

const PaymentItem = ({
  name, value, isBold, testId, startDate, endDate,
}) => (
  <tr
    data-testid={testId}
    style={{
      fontWeight: isBold ? '1000' : 'inherit',
    }}
  >
    <td>
      {name}
      {(startDate && endDate)
      && <span>{` (${formatDateFromSFDC(startDate)} - ${formatDateFromSFDC(endDate)})`}</span>}
    </td>
    <td>{`${value < 0 ? '-' : ''}£${numberTo2dp(value)}`}</td>
  </tr>
);

PaymentItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  testId: PropTypes.string,
  isBold: PropTypes.bool,
};

PaymentItem.defaultProps = {
  startDate: null,
  endDate: null,
  testId: '',
  isBold: false,
};

export default PaymentItem;
