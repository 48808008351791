import React from 'react';
import PropTypes from 'prop-types';
import './SavedInput.scss';

const SavedInput = ({ label, value, id }) => (
  <div className="savedInput row">

    <span className="savedInput__label col-sm-5">{label}</span>
    <span
      className={`savedInput__value col-sm-7 ${
        id === 'email' ? 'savedInput__value--lowercase' : ''}`}
      data-testid="savedInput__value"
    >
      {value}


    </span>
  </div>
);

SavedInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  value: PropTypes.string,
};

SavedInput.defaultProps = {
  id: null,
  value: '',
};
export default SavedInput;
