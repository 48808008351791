import awsExports from './aws-exports';

export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_TEST = process.env.NODE_ENV === 'test';
export const IS_MOCKED = process.env.REACT_APP_API_MOCK === 'true';
export const IS_E2E = !!window.Cypress;
export const TIMEOUT = IS_TEST ? 0 : 300;

// http responses
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const NOT_FOUND = 404;
export const CONFLICT = 409;

export const DEFAULT_CURRENCY = 'GBP';
export const DEFAULT_COUNTRY = 'GB';

export const VALID_ROLES = [
  '1 Lead Tenant',
  '2 Tenant',
  '3 Guarantor',
  '4 Permitted Occupier',
];

export const ROLES = {
  LEAD_TENANT: 'LeadTenant',
  TENANT: 'Tenant',
  GUARANTOR: 'Guarantor',
  OCCUPIER: 'PermittedOccupier',
};

// Salesforce
export const SF_ROLES = {
  LeadTenant: '1 Lead Tenant',
  Tenant: '2 Tenant',
  Guarantor: '3 Guarantor',
  PermittedOccupier: '4 Permitted Occupier',
};

export const UNIT_STATUSES = [
  'On the Market Available to View',
  'On the Market not Available to View',
  'Leased - Up for Renewal',
];

export const SF_TENANCY_TYPES = {
  AST: 'New',
  RENEWAL: 'Renewal',
};

export const SF_AGREEMENT_TYPES = {
  COMPANYLET: 'Non-Housing (Company)',
};

export const SF_OPPORTUNITY_STAGES = {
  PRE_VIEWING: 'Pre Viewing',
  BOOKED_VIEWING: 'Booked Viewing',
  POST_VIEWING: 'Post Viewing',
  SEND_OFFER: 'Send offer',
  OFFER_SELECTED: 'Offer Selected',
  REFERENCES_REQUESTED: 'References Requested',
  ON_REFERENCING: 'On Referencing',
  MOVE_IN_MONIES: 'Move in Monies',
  SIGNING: 'Signing',
  AUDIT: 'Audit',
};

export const OFFER_STATUSES = {
  ON_HOLD: 'On Hold - In Basket',
};

export const ROLE_REFERENCING_STATUSES = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
};

export const OPPORTUNITY_REFERENCING_STATUSES = [
  'REFERENCES_REQUESTED',
  'ON_REFERENCING',
];

export const PAYMENT_STAGES = {
  EXEMPT: 'Collected from customer',
  NON_EXEMPT: 'Awaiting submission',
};

export const UNAUTHORIZED_RECORD_STATUS = 'Awaiting submission';

export const PAYMENT_SUB_TYPE = {
  ADVANCED: 'ADVPP',
  MOVE_IN: 'Move-In Monies',
};

export const PAYMENT_ROUTE = 'Direct Debit';

export const REFERENCES_APPROVED = 'Approved';

// Asperato
export const ASPERATO_STATUSES = {
  SUCCESS: 'asp--exit-screen',
  ERROR: 'asp--error',
  CANCEL: 'asp--cancel',
  VALUE: 'asp--error--value',
};

// Config
export const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const APIGW = 'ApiGW';

/**
 * If ENDPOINT specified then we gonna use that as endpoint
 * .env.development sets endpoint to localhost:3001
 */
if (ENDPOINT) {
  awsExports.aws_cloud_logic_custom.find((it) => it.name === APIGW).endpoint = ENDPOINT;
}

export const AWS_EXPORTS = awsExports;
