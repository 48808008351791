import React from 'react';
import { Link } from 'react-router-dom';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import { PATHS } from '../../navigationConstants';
import Button from '../../components/atoms/Button/Button';
import { signCopy } from '../../content/main.json';

const { title, category, button } = signCopy;

const Sign = () => (

  <>
    <TitleHeader title={title} category={category} large />
    <Link to={PATHS.DIRECT_DEBIT}>
      <Button title={button} testId="signButton" />
    </Link>
  </>
);


export default Sign;
