import React from 'react';
import xss from 'xss';

import { updateOpportunity } from '../../api/salesforce';
import { UserContext } from '../../providers/UserContext';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import Button from '../../components/atoms/Button/Button';
import RadioInput from '../../components/atoms/RadioInput/RadioInput';
import { speakToGraingerCopy } from '../../content/main.json';
import './SpeakToGrainger.scss';

const {
  title, content, subTitle, subTitleForAcceptingCallback, options, button,
} = speakToGraingerCopy;

const radioOptions = Object.keys(options).map((key) => ({
  value: key,
  label: options[key],
}));

const SpeakToGrainger = () => {
  const [callBackRequested, setCallBackRequested] = React.useState(false);
  const [selected, setSelected] = React.useState(false);
  const { opportunityId } = React.useContext(UserContext);

  const submitUserRequestForCallback = async () => {
    await updateOpportunity(opportunityId, {
      callbackRequested: true,
      callbackRequestedSlot: options[selected],
    });
    setCallBackRequested(true);
  };

  return (
    <div className="speak-to-grainger" data-testid="speak-to-grainger">
      <TitleHeader
        title={title}
        large
      />

      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={{ __html: xss(content) }} />

      {callBackRequested ? <h3>{subTitleForAcceptingCallback}</h3> : (
        <>
          <h3>{subTitle}</h3>
          <div
            className="speak-to-grainger__radio-group"
            onChange={(e) => setSelected(e.target.value)}
          >
            {radioOptions.map(
              (item) => (
                <RadioInput
                  id={item.value}
                  key={`callback-option-${item.value}`}
                  className="alternateRadio"
                  name="callback-options"
                  label={item.label}
                  value={item.value}
                />
              ),
            )}
          </div>
          <br />
          <Button
            title={button}
            testId="speak-to-grainger-save-button"
            disabled={!selected}
            onClick={submitUserRequestForCallback}
          />
        </>
      )}
    </div>
  );
};

export default SpeakToGrainger;
