import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import Summary from '../../components/molecules/Summary/Summary';
import Button from '../../components/atoms/Button/Button';
import { PATHS } from '../../navigationConstants';
import { householdSummaryCopy } from '../../content/main.json';
import { WalesContext } from '../../providers/WalesContext';

const NewSummary = ({ confirm, submitting }) => {

  const { isWales } = useContext(WalesContext);
  let houseHoldSummaryTerm = !isWales ? householdSummaryCopy : householdSummaryCopy.wales;

  return (
    <>
      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={{ __html: houseHoldSummaryTerm.newTenancyIntro }} />

      <Summary />

      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={{ __html: houseHoldSummaryTerm.newTenancyConfirm }} />
      <br />

      <div className="householdSummary__buttons">
        <Link to={PATHS.HOUSEHOLD}>
          <Button title="Back" secondary />
        </Link>
        <Button
          testId="confirm-household"
          title={houseHoldSummaryTerm.button}
          onClick={confirm}
          disabled={submitting}
        />
      </div>
    </>
  )
};

NewSummary.propTypes = {
  confirm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default NewSummary;
