import React from 'react';
import PropTypes from 'prop-types';
import TitleHeader from '../TitleHeader/TitleHeader';
import RadioInput from '../../atoms/RadioInput/RadioInput';
import { replaceSpaces } from '../../../helpers/utils/generic';

const Periodicity = ({
  terms, quoteIndex, setPeriodicity,
}) => (
  <div
    className="tenancyTerm__schedule-block"
    data-testid="periodicity-options"
  >
    <TitleHeader title="Which payment schedule would you like?" />
    <div className="tenancyTerm__form-container row" onChange={(e) => setPeriodicity(e.target.value)}>
      {terms.map((periodicity) => (
        <div
          key={`${replaceSpaces(periodicity, '_')}-${quoteIndex}`}
          className="tenancyTerm__term col-md-6 col-lg-4"
        >
          <RadioInput
            id={`${replaceSpaces(periodicity, '_')}-${quoteIndex}`}
            name={`radio-group-periodicity-${quoteIndex}`}
            label={periodicity}
            value={periodicity}
          />
        </div>
      ))}
    </div>
  </div>
);

Periodicity.propTypes = {
  terms: PropTypes.instanceOf(Array).isRequired,
  quoteIndex: PropTypes.number,
  setPeriodicity: PropTypes.func.isRequired,
};

Periodicity.defaultProps = {
  quoteIndex: null,
};

export default Periodicity;
