import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import { PATHS } from '../../navigationConstants';
import Button from '../../components/atoms/Button/Button';
import { referencingInfoCopy } from '../../content/main.json';
import { WalesContext } from '../../providers/WalesContext';

import './ReferencingInfo.scss';
import { UserContext } from '../../providers/UserContext';

const Referencinginfo = () => {
  const { isWales }= useContext(WalesContext);
  const { referenceProvider } = useContext(UserContext);

  let referencingInfoTerms = !isWales ? referencingInfoCopy : referencingInfoCopy.wales;

  return (
    <>
      <TitleHeader
        title={referencingInfoTerms.title}
        category={referencingInfoTerms.category}
        large
      />
      {/* eslint-disable-next-line react/no-array-index-key */}
      {referencingInfoTerms.introduction.map((line, index) => <p key={`introduction-${index}`}>{(referenceProvider || '').toLowerCase() === 'homelet' ? line.replace('Rightmove', 'Homelet') : line}</p>)}
      <hr noshade="true" />
      <p>{referencingInfoTerms.description}</p>
      <ol className="referencing-info__list">
        {referencingInfoTerms.info.map(
          (item, index) => <li key={`info-${index}`}>{item}</li>, // eslint-disable-line react/no-array-index-key
        )}
      </ol>
      <br />
      <Link to={PATHS.REFERENCING}>
        <Button title={referencingInfoTerms.nextButton} testId="referencing-info-next" />
      </Link>
    </>
  );
}

export default Referencinginfo;
