import { renewalChangeFieldAttributes } from '../content/main.json';

const {
  addOrRemoveTenant, addOrRemoveGuarantor, changeTenancyTerm, changePeriodicity, terminate, other,
} = renewalChangeFieldAttributes;

const renewalChangeRequestFormDefinition = {
  checkboxFields: [
    {
      id: addOrRemoveTenant.id,
      label: addOrRemoveTenant.webRenewalChange,
      value: addOrRemoveTenant.sfRenewalChange,
    },
    {
      id: addOrRemoveGuarantor.id,
      label: addOrRemoveGuarantor.webRenewalChange,
      value: addOrRemoveGuarantor.sfRenewalChange,
    },
    {
      id: changeTenancyTerm.id,
      label: changeTenancyTerm.webRenewalChange,
      value: changeTenancyTerm.sfRenewalChange,
    },
    {
      id: changePeriodicity.id,
      label: changePeriodicity.webRenewalChange,
      value: changePeriodicity.sfRenewalChange,
    },
    {
      id: terminate.id,
      label: terminate.webRenewalChange,
      value: terminate.sfRenewalChange,
    },
    {
      id: other.id,
      label: other.webRenewalChange,
      value: other.sfRenewalChange,
    },
  ],
  notesField: {
    id: 'notes',
    label: 'Notes',
    message: 'Please tell us more about the changes',
  },
};

export default renewalChangeRequestFormDefinition;
