import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TitleHeader from '../TitleHeader/TitleHeader';
import Button from '../../atoms/Button/Button';
import './ErrorWrapper.scss';

const ErrorWrapper = ({
  children, title, linkText, to,
}) => (
  <div className="error gr-error">
    <TitleHeader title={title} large />
    {(typeof children === 'string')
      // eslint-disable-next-line react/no-danger
      ? <p dangerouslySetInnerHTML={{ __html: children }} />
      : children}
    <br />
    <br />
    {linkText && to && (
      <Link to={to}>
        <Button title={linkText} />
      </Link>
    )}
  </div>
);

ErrorWrapper.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  linkText: PropTypes.string,
  to: PropTypes.string,

};

ErrorWrapper.defaultProps = {
  children: null,
  title: 'Sorry...',
  linkText: null,
  to: null,
};

export default ErrorWrapper;
