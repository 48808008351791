import React from 'react';
import PropTypes from 'prop-types';
import './TextInput.scss';

const TextInput = ({
  label,
  type,
  id,
  value,
  required,
  disabled,
  validation,
  hasError,
  register,
  className,
  maxLength,
  placeholder,
  testId,
  onBlur,
  isBlur,
  onBlurMessage,
}) => {
  const showWarning = hasError || isBlur;
  const errorMessage = hasError ? hasError.message : onBlurMessage;

  return (
    <div className={`textInput ${className}`}>
      {label && (
        <label className="textInput__label" htmlFor={id}>
          {`${label}${
            required ? ' *' : ''
          }`}
        </label>
      )}
      <input
        className={`textInput__input ${
          showWarning ? 'textInput__input--error' : ''
        }`}
        id={id}
        name={id}
        maxLength={maxLength}
        placeholder={placeholder}
        type={type}
        defaultValue={value || null}
        disabled={!!disabled}
        ref={register(validation)}
        required={required}
        data-testid={testId}
        onBlur={onBlur}
      />
      {showWarning && (
        <span className="textInput__error-message" data-testid="test-error">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  value: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  register: PropTypes.func,
  className: PropTypes.string,
  validation: PropTypes.instanceOf(Object),
  hasError: PropTypes.instanceOf(Object),
  testId: PropTypes.string,
  onBlur: PropTypes.func,
  onBlurMessage: PropTypes.string,
  isBlur: PropTypes.bool,
};

TextInput.defaultProps = {
  className: '',
  label: '',
  type: 'text',
  value: null,
  id: null,
  placeholder: null,
  maxLength: null,
  required: false,
  disabled: false,
  register: () => {},
  validation: {},
  hasError: null,
  testId: '',
  onBlur: null,
  onBlurMessage: null,
  isBlur: false,
};

export default TextInput;
