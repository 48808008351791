import React from 'react';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import useBackController from './helpers/hooks/useBackController/useBackController';
import { PATHS } from './navigationConstants';
import {
  privateRoutes,
  errorRoutes,
} from './routes';
import AppLayout from './AppLayout';
import ErrorLayout from './ErrorLayout';
import AppWithAuth from './AppWithAuth';

import CreateAccount from './pages/CreateAccount/CreateAccount';
import AuthProvider, { AuthContext } from './providers/AuthContext';
import ContactRoleProvider from './providers/ContactRoleContext';
import BasketProvider from './providers/BasketContext';
import UserProvider from './providers/UserContext';
import PaymentProvider from './providers/PaymentContext';
import WalesProvider from './providers/WalesContext';
import { AWS_EXPORTS } from './appConstants';


const AppRouter = () => {
  useBackController([...privateRoutes, ...errorRoutes]);

  // Define the base URL for iframe sources based on environment
  let iframeBaseUrl = process.env.REACT_APP_HOMELET_ENDPOINT || ''
  iframeBaseUrl = iframeBaseUrl.match(/^https?:\/\/[^/]+/)[0];

  // Construct the Cognito Identity Provider endpoint URL based on the region
  const cognitoEndpoint = `https://cognito-idp.${AWS_EXPORTS?.aws_cognito_region}.amazonaws.com`;
  const cognitoSecurityEndPoint = `https://cognito-identity.${AWS_EXPORTS?.aws_cognito_region}.amazonaws.com`;
  let apiUrl = process.env.REACT_APP_API_ENDPOINT || AWS_EXPORTS?.aws_cloud_logic_custom[0]?.endpoint
  apiUrl = process.env.REACT_APP_API_ENDPOINT || apiUrl.match(/^https?:\/\/[^/]+/)[0];
  // console.log(apiUrl);



  // Define the CSP dynamically based on environment
  const csp = `
    connect-src 'self' ${cognitoEndpoint} ${cognitoSecurityEndPoint} ${apiUrl};
    default-src 'self';
    img-src 'self';
    manifest-src 'self';
    script-src 'self';
    style-src 'self';
    font-src 'self';
    media-src 'self';
    object-src 'self' data:;
    frame-src *;
    `;

  // Function to set dynamic CSP
  const setDynamicCSP = () => {
    const meta = document.createElement('meta');
    meta.httpEquiv = 'Content-Security-Policy';
    meta.content = csp;

    // Get the first child of head (if any)
    const firstChild = document.head.firstChild;

    // Insert the meta tag before the first child of head
    if (firstChild) {
      document.head.insertBefore(meta, firstChild);
    } else {
      // If there are no children in head, append it
      document.head.appendChild(meta);
    }
  };

  // Call the function to set CSP when component mounts
  React.useEffect(() => {
    setDynamicCSP();
  }, []);


  return (
    <AuthProvider>
      <AuthContext.Consumer>
        {({ authenticated }) => (
          authenticated ? (
            <Switch>
              {errorRoutes.map(({
                Component,
                path,
              }) => (
                <Route
                  path={path}
                  key={path}
                  exact
                  render={(props) => (
                    <ErrorLayout>
                      <Component {...props} />
                    </ErrorLayout>
                  )}
                />
              ))}
              <UserProvider>
                <WalesProvider>
                  <BasketProvider>
                    <PaymentProvider>
                      <ContactRoleProvider>
                        <Switch>
                          {privateRoutes.map(({
                            Component,
                            path,
                            exact = true,
                            basket = true,
                          }) => (
                            <Route
                              path={path}
                              key={path}
                              exact={exact}
                              render={(props) => (
                                <AppLayout basket={basket}>
                                  <Component {...props} />
                                </AppLayout>
                              )}
                            />
                          ))}
                        </Switch>
                      </ContactRoleProvider>
                    </PaymentProvider>
                  </BasketProvider>
                </WalesProvider>
              </UserProvider>
            </Switch>
          ) : (
            <Switch>
              <Route path={PATHS.SIGNUP} key={PATHS.SIGNUP} component={CreateAccount} />
              <Route exact path={PATHS.ROOT} key={PATHS.ROOT} component={AppWithAuth} />
              <Redirect to="/" />
            </Switch>
          )
        )}
      </AuthContext.Consumer>
    </AuthProvider>
  );
};

export default AppRouter;
