import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { Auth } from 'aws-amplify';
import { IS_E2E } from '../appConstants';

let initialState;

/* ************************ THIS IS A WORKAROUND FOR THE E2E ************************ */
/* *************** TO BE ABLE TO SIGN IN through localStorage details *************** */
if (IS_E2E && localStorage.getItem('amplify-authenticator-authState') === 'signedIn') {
  initialState = {
    authenticated: true,
    attributes: {},
  };
  // Auth.currentAuthenticatedUser = () => Promise.resolve({
  //   attributes: {
  //     'custom:role': '1 Lead Tenant',
  //   },
  // });
}
/* ************************ THIS IS A WORKAROUND FOR THE E2E ************************ */

export const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(initialState || {
    authenticated: false,
    attributes: {},
  });

  return (
    <AuthContext.Provider
      value={{ ...auth, setAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AuthProvider;
