import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { ContactRoleContext } from '../../../providers/ContactRoleContext';
import { WalesContext } from '../../../providers/WalesContext';
import TitleHeader from '../TitleHeader/TitleHeader';
import ContactRoleForm from '../ContactRoleForm/ContactRoleForm';
import Button from '../../atoms/Button/Button';
import { ROLES } from '../../../appConstants';
import './AddContactRole.scss';

import { yourHouseholdCopy } from '../../../content/main.json';


const AddContactRole = ({
  copy,
  fields,
  extensionFields,
  sfRole,
  buttonTestId,
  appHistory,
}) => {
  const contactRoleContext = useContext(ContactRoleContext);
  const { isWales } = useContext(WalesContext);
  let yourHouseholdTerms = !isWales ? yourHouseholdCopy : yourHouseholdCopy.wales;

  const [disabled, setDisabled] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const roleNameContext = sfRole;
  const contactArray = contactRoleContext.householdData[roleNameContext];

  const {
    maxContactsCopy,
  } = yourHouseholdTerms;

  const addContact = () => {
    setDisabled(true);
    const tempArr = contactRoleContext.householdData;
    tempArr[roleNameContext].push({});
    contactRoleContext.setHouseholdData(tempArr);
  };

  const maxRoles = {
    [ROLES.TENANT]: 3,
    [ROLES.GUARANTOR]: 4,
    [ROLES.OCCUPIER]: 10,
  };

  useEffect(() => {
    if (isEditing || contactArray.length >= maxRoles[roleNameContext]) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [contactRoleContext]);

  return (
    <div className="addContactRole">
      <TitleHeader
        category={copy.heading}
      />
      {copy.information && copy.information.map((paragraph) => (
        <p key={uuid()}>{paragraph}</p>
      ))}
      {contactArray
      && contactArray.map((c, index) => (
        <ContactRoleForm
          key={uuid()}
          fields={fields}
          extensionFields={extensionFields}
          setDisabled={setDisabled}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          title={`${copy.role} ${sfRole === ROLES.TENANT ? index + 2 : index + 1}`}
          role={sfRole}
          index={index}
          appHistory={appHistory}
        />
      ))}
      { contactArray.length >= maxRoles[roleNameContext]
        && <p><em>{maxContactsCopy[roleNameContext]}</em></p>}
      <Button
        title={copy.addButton}
        type="button"
        secondary
        onClick={addContact}
        disabled={disabled}
        testId={buttonTestId}
      />
    </div>
  );
};

AddContactRole.propTypes = {
  copy: PropTypes.instanceOf(Object).isRequired,
  fields: PropTypes.arrayOf(Object).isRequired,
  extensionFields: PropTypes.arrayOf(Object),
  sfRole: PropTypes.string.isRequired,
  buttonTestId: PropTypes.string.isRequired,
  appHistory: PropTypes.instanceOf(Object),
};

AddContactRole.defaultProps = {
  extensionFields: [],
  appHistory: {},
};

export default AddContactRole;
