import React from 'react';
import { PATHS } from '../../navigationConstants';
import ErrorWrapper from '../../components/molecules/ErrorWrapper/ErrorWrapper';
import {
  pageNotFound,
  unitUnavailable,
  paymentFailed,
  noActiveOpportunities,
  paymentTimeout,
  referencingFormUnavailable,
  referencingInvalidApplicant,
  generic,
} from '../../content/errors.json';

export const ErrorPageNotFound = () => (
  <ErrorWrapper
    title={pageNotFound.title}
    linkText={pageNotFound.linkText}
    to={PATHS.ROOT}
  >
    {pageNotFound.content}
  </ErrorWrapper>
);

export const ErrorUnitUnavailable = () => (
  <ErrorWrapper
    title={unitUnavailable.title}
    linkText={unitUnavailable.linkText}
    to={PATHS.BUILD_OFFER}
  >
    {unitUnavailable.content}
  </ErrorWrapper>
);

export const ErrorPaymentFailed = () => (
  <ErrorWrapper
    title={paymentFailed.title}
    linkText={paymentFailed.linkText}
    to={PATHS.ADVANCE_PAYMENT}
  >
    {paymentFailed.content}
  </ErrorWrapper>
);

export const ErrorNoActiveOpportunities = () => (
  <ErrorWrapper
    title={noActiveOpportunities.title}
  >
    {noActiveOpportunities.content}
  </ErrorWrapper>
);

export const ErrorPaymentTimeout = () => (
  <ErrorWrapper
    title={paymentTimeout.title}
    linkText={paymentTimeout.linkText}
    to={PATHS.BUILD_OFFER}
  >
    {paymentTimeout.content}
  </ErrorWrapper>
);

export const ErrorReferencingFormUnavailable = () => (
  <ErrorWrapper
    title={referencingFormUnavailable.title}
  >
    {referencingFormUnavailable.content}
  </ErrorWrapper>
);
export const ErrorReferencingInvalidApplicant = () => (
  <ErrorWrapper
    title={referencingInvalidApplicant.title}
    linkText={referencingInvalidApplicant.linkText}
    to={PATHS.REFERENCING_INFO}
  >
    {referencingInvalidApplicant.content}
  </ErrorWrapper>
);

export const ErrorGeneric = () => (
  <ErrorWrapper title={generic.title}>
    {generic.content}
  </ErrorWrapper>
);
