import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import { PATHS } from '../../navigationConstants';
import { PAYMENT_ROUTE } from '../../appConstants';
import {
  getOpportunity,
  raiseAuthorisationRecord,
  getAuthorisationRecord,
  getContactAuthorisations,
  getOcrDdAmount,
} from '../../api/salesforce';
import { directDebitCopy } from '../../content/main.json';
import Table from '../../components/atoms/Table/Table';
import Loading from '../../components/atoms/Loading/Loading';
import { extractAsperatoIframeURL, formatDateFromSFDC } from '../../helpers/utils/generic';
import AsperatoIFrame from '../../components/molecules/AsperatoIFrame/AsperatoIFrame';
import DirectDebitForm from '../../components/molecules/DirectDebitForm/DirectDebitForm';
import { UserContext } from '../../providers/UserContext';
import { WalesContext } from '../../providers/WalesContext';
import Button from '../../components/atoms/Button/Button';

import './DirectDebit.scss';


const DirectDebit = ({ history }) => {
  const [url, setUrl] = useState(null);
  const [opportunityData, setOpportunityData] = useState(null);
  const [ddAmount, setDdAmount] = useState(null);
  const [loading, setLoading] = useState(true);
  const { opportunityId, contactId, ocrId } = useContext(UserContext);
  const { isWales } = useContext(WalesContext);
  let directDebitTerm = !isWales ? directDebitCopy : directDebitCopy.wales;

  const {
    title, category, information, formTitle, iframeMessage, nextButton, skip,
  } = directDebitTerm;


  useEffect(() => {
    // eslint-disable-next-line consistent-return
    async function fetchData() {
      let opportunity;
      try {
        opportunity = await getOpportunity(opportunityId);
        setOpportunityData(opportunity);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Could NOT get the Opportunity');
        return history.push(PATHS.ERROR);
      }

      const amount = await getOcrDdAmount(ocrId);
      setDdAmount(amount);

      try {
        let authorisationRecord;
        authorisationRecord = await getContactAuthorisations(contactId, PAYMENT_ROUTE);

        if (!authorisationRecord) {
          const { id: authorisationId } = await raiseAuthorisationRecord(contactId);
          authorisationRecord = await getAuthorisationRecord(authorisationId);
        }

        const { iframeUrl } = authorisationRecord;

        setUrl(extractAsperatoIframeURL(iframeUrl));
        setLoading(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Could NOT start authorisation or no authorisation record exists against the contact');
        return history.push(PATHS.ERROR);
      }
    }
    fetchData();
  }, [opportunityId, contactId, history]);

  return (
    <div className="directDebit">
      <TitleHeader title={title} category={category} large />
      {/* eslint-disable-next-line react/no-array-index-key */}
      {information.map((text, index) => <p key={`direct-debit-info-${index}`}>{text}</p>)}

      {
        loading
          ? <Loading />
          : (
            <>
              <Table testId="directDebitPaymentSummary">
                <thead>
                  <tr>
                    <td>Details of your DD payments</td>
                    <td>Date</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>First DD collection date</td>
                    <td>
                      {opportunityData && opportunityData.firstDDCollection
                        && formatDateFromSFDC(opportunityData.firstDDCollection)}
                    </td>
                  </tr>
                  <tr>
                    <td>Last DD collection date</td>
                    <td>
                      {opportunityData && opportunityData.lastDDCollection
                        && formatDateFromSFDC(opportunityData.lastDDCollection)}
                    </td>
                  </tr>
                </tbody>
              </Table>

              <DirectDebitForm title={formTitle} testId="directDebitForm" ddAmount={ddAmount} />

              <AsperatoIFrame
                src={url}
                iframeLoadingMessage={iframeMessage}
                appHistory={history}
                sucessPath={PATHS.APPLICATION_COMPLETE}
              />
            </>
          )
      }

      <p>
        {skip}
        {' '}
        <Link to={PATHS.APPLICATION_COMPLETE} data-testid="setUpLater">
          <Button
            title={nextButton}

          />
        </Link>
      </p>

    </div>
  );
};
DirectDebit.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default DirectDebit;
