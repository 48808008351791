import React from 'react';
import PropTypes from 'prop-types';

import './Table.scss';

const Table = ({ children, testId }) => (
  children && (
  <table
    className="table"
    data-testid={testId}
  >
    {children}
  </table>
  )
);

Table.propTypes = {
  children: PropTypes.node,
  testId: PropTypes.string,
};

Table.defaultProps = {
  children: null,
  testId: '',
};

export default Table;
