import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import './YourHousehold.scss';
import { v4 as uuid } from 'uuid';
import { Link } from 'react-router-dom';
import HouseholdForm from '../../components/molecules/HouseholdForm/HouseholdForm';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import Button from '../../components/atoms/Button/Button';
import Loading from '../../components/atoms/Loading/Loading';
import { PATHS } from '../../navigationConstants';
import { yourHouseholdCopy } from '../../content/main.json';
import { getHouseholdSummary } from '../../api/salesforce';
import { ContactRoleContext } from '../../providers/ContactRoleContext';
import transformGetHouseholdSummary from '../../helpers/utils/transformGetHouseholdSummary';
import { UserContext } from '../../providers/UserContext';
import { WalesContext } from '../../providers/WalesContext';

const YourHousehold = ({ history }) => {
  const { isWales } = useContext(WalesContext);
  let yourHouseholdTerms = !isWales ? yourHouseholdCopy : yourHouseholdCopy.wales;

  const [loading, setLoading] = useState(true);
  const { opportunityId } = useContext(UserContext);
  const { householdData, setHouseholdData } = useContext(ContactRoleContext);
  const { LeadTenant: LeadTenantArray } = householdData;
  const LeadTenant = LeadTenantArray[0];

  useEffect(() => {
    async function fetchData() {
      const householdMembersData = await getHouseholdSummary(opportunityId);
      const householdMembersDetails = transformGetHouseholdSummary(
        householdMembersData,
      );
      const tempArray = {
        LeadTenant: [],
        Tenant: [],
        PermittedOccupier: [],
        Guarantor: [],
      };

      // eslint-disable-next-line no-return-assign
      householdMembersDetails.forEach((member) => {
        tempArray[member.role].push(member);
      });
      setHouseholdData(tempArray);
      setLoading(false);
    }
    fetchData();
  }, [opportunityId, setHouseholdData]);

  return (
    <>
      <TitleHeader
        title={yourHouseholdTerms.title}
        category={yourHouseholdTerms.heading}
        large
      />
      {yourHouseholdTerms
        && yourHouseholdTerms.introduction
        && yourHouseholdTerms.introduction.map((paragraph) => (
          <p key={uuid()}>{paragraph}</p>
        ))}
      {loading && <Loading />}
      {!loading && <HouseholdForm appHistory={history} />}
      <br />

      <div className="householdSummary__buttons">
        <Link to={PATHS.BUILD_OFFER}>
          <Button title={yourHouseholdTerms.backButton} secondary />
        </Link>
        <Link to={PATHS.HOUSEHOLD_SUMMARY}>
          <Button disabled= {(LeadTenant && LeadTenant.fields && LeadTenant.fields.length < 4)} title={yourHouseholdTerms.nextButton} testId="household-next-button" />
        </Link>
      </div>
    </>
  );
};
YourHousehold.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};
export default YourHousehold;
