import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import './Quote.scss';
import Button from '../../atoms/Button/Button';
import UnitImages from '../UnitImages/UnitImages';
import Card from '../../atoms/Card/Card';
import { UNIT_STATUSES } from '../../../appConstants';
import { getUnitImages } from '../../../api/storage';
import { WalesContext } from '../../../providers/WalesContext';

const RenewalQuote = ({
  unitName,
  estate,
  monthlyRent,
  term,
  unitStatus,
  selected,
  index,
  imageUrl,
  setSelectedQuoteIndex,
  names,
  readOnly,
}) => {
  const unitUnavailable = !UNIT_STATUSES.includes(unitStatus);
  const { isWales } = useContext(WalesContext);
  const onClickChooseHome = () => {
    setSelectedQuoteIndex(index);
  };

  const [imageKeys, setImageKeys] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (imageUrl) {
        getUnitImages(imageUrl).then((signedKeys) => {
          setImageKeys(signedKeys.filter((file) => file.match(/\.(jpg|png|gif)/)));
        });
      }
    }
    fetchData();
  }, [imageUrl]);

  return (
    <div
      className={`quote ${selected ? 'quote--selected' : ''}`}
      disabled={unitUnavailable}
      data-testid="quote"
    >
      <Card>
        <UnitImages images={imageKeys} />
        { unitUnavailable
          && <div className="quote__banner">This home is no longer available</div>}

        <div className="quote__content">
          <h2 data-testid="quote-unit-name">{`Offer ${index + 1}`}</h2>
          <p>{unitName}</p>
          <p dangerouslySetInnerHTML={{ __html: estate }} />

          <div className="quote__terms">
            <h4>Rent</h4>
            {monthlyRent && (
              <h3 className="unit-attribute">{`£${monthlyRent}/month`}</h3>
            )}
            <h4>{`${!isWales ? 'Tenancy term' : 'Occupation Contract term' }`}</h4>
            <p className="unit-attribute">
              {term}
            </p>
          </div>

          <h4>{`${!isWales ? 'Tenants' : 'Contract Holders' }`}</h4>

          {names.map((name) => (
            <p
              key={name}
            >
              {name}
            </p>
          ))}
          { !readOnly
          && (
            <div className="quote__button">
              <Button
                title="Choose this offer"
                disabled={unitUnavailable}
                onClick={() => onClickChooseHome()}
                testId="choose-this-home"
              />
            </div>
          )}

        </div>
      </Card>
    </div>
  );
};

RenewalQuote.propTypes = {
  unitName: PropTypes.string.isRequired,
  estate: PropTypes.string.isRequired,
  monthlyRent: PropTypes.number.isRequired,
  unitStatus: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  setSelectedQuoteIndex: PropTypes.func.isRequired,
  imageUrl: PropTypes.string,
  term: PropTypes.string.isRequired,
  names: PropTypes.instanceOf(Array).isRequired,
  readOnly: PropTypes.bool,
};

RenewalQuote.defaultProps = {
  imageUrl: null,
  readOnly: false,
};

export default RenewalQuote;
