import React, { useContext } from 'react';
import { v4 as uuid } from 'uuid';
import { ContactRoleContext } from '../../../providers/ContactRoleContext';
import { WalesContext } from '../../../providers/WalesContext';
import Card from '../../atoms/Card/Card';
import SavedInput from '../../atoms/SavedInput/SavedInput';
import TitleHeader from '../TitleHeader/TitleHeader';

const Summary = () => {
  const contactRoleContext = useContext(ContactRoleContext);
  const { isWales } = useContext(WalesContext);
  const {
    householdData: {
      LeadTenant, Tenant, PermittedOccupier, Guarantor,
    },
  } = contactRoleContext;

  const householdMembers = Tenant.length + PermittedOccupier.length + 1;
  const guarantors = Guarantor.length;

  return (
    <div>
      <br />
      <p>{`Your household has ${householdMembers} member(s)`}</p>
      <div
        className="row summary__householdMembers"
        data-testid="summary__householdMembers"
      >
        <div className="col-xl-6">
          <Card>
            <TitleHeader category={`${!isWales ? "Tenant 1": "Contract Holder 1"}`} />
            {LeadTenant[0]
              && LeadTenant[0].fields
              && LeadTenant[0].fields.map((field) => (
                <React.Fragment key={uuid()}>
                  {field.value && (
                    <SavedInput
                      id={field.id}
                      label={field.label}
                      value={field.value}
                    />
                  )}
                </React.Fragment>
              ))}
          </Card>
        </div>
        {Tenant.map((addTenant, index) => (
          <div className="col-xl-6" key={uuid()}>
            <Card>
              <TitleHeader category={`${!isWales ? "Tenant": "Contract Holder"} ${index + 2}`} />
              {addTenant.fields.map((field) => (
                <React.Fragment key={uuid()}>
                  {field.value && (
                    <SavedInput
                      id={field.id}
                      label={field.label}
                      value={field.value}
                    />
                  )}
                </React.Fragment>
              ))}
            </Card>
          </div>
        ))}
        {PermittedOccupier.map((occupant, index) => (
          <div className="col-xl-6" key={uuid()}>
            <Card>
              <TitleHeader category={`Occupant ${index + 1}`} />
              {occupant.fields.map((field) => (
                <React.Fragment key={uuid()}>
                  {field.value && (
                    <SavedInput
                      id={field.id}
                      label={field.label}
                      value={field.value}
                    />
                  )}
                </React.Fragment>
              ))}
            </Card>
          </div>
        ))}
      </div>
      {Guarantor && Guarantor.length > 0 && (
        <>
          <p>{`You have ${guarantors} guarantor(s)`}</p>
          <div className="row">
            {Guarantor.map((guarant, gindex) => (
              <div className="col-xl-6" key={uuid()}>
                <Card key={uuid()}>
                  <TitleHeader category={`Guarantor ${gindex + 1}`} />
                  {guarant.fields.map((field) => (
                    <React.Fragment key={uuid()}>
                      {field.value && (
                        <SavedInput
                          id={field.id}
                          label={field.label}
                          value={field.value}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </Card>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Summary;
