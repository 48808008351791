import React from 'react';
import PropTypes from 'prop-types';
import './EmailInput.scss';

const EmailInput = ({
  label, id, value, required, disabled, hasError, validation,
  register, className, maxLength, placeholder, testId,
}) => (
  <div className={`emailInput ${className}`}>
    {label && <label className="emailInput__label" htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>}
    <input
      className={`emailInput__input ${hasError ? 'emailInput__input--error' : ''}`}
      id={id}
      name={id}
      maxLength={maxLength}
      placeholder={placeholder}
      type="email"
      defaultValue={value || null}
      disabled={!!disabled}
      ref={register(validation)}
      data-testid={testId}
    />
    {hasError
        && <span className="emailInput__error-message" data-testid="test-error">{hasError.message}</span>}
  </div>
);

EmailInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  register: PropTypes.func,
  validation: PropTypes.instanceOf(Object),
  hasError: PropTypes.instanceOf(Object),
  testId: PropTypes.string,
};

EmailInput.defaultProps = {
  className: '',
  label: '',
  id: 'email',
  value: '',
  placeholder: null,
  maxLength: '255',
  required: false,
  disabled: false,
  register: () => { },
  validation: {},
  hasError: null,
  testId: '',
};
export default EmailInput;
