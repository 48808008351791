import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../atoms/Button/Button';
import './Modal.scss';

const Modal = ({
  modalState, modalName, setModalState, children,
}) => (
  <div className="modal" data-testid="modal">
    <div className="modal__container">
      {children}
      <div className="modal__close">
        <Button
          title="close"
          onClick={() => {
            const newState = { ...modalState };
            newState[modalName] = false;
            setModalState({ ...newState });
          }}
        />
      </div>
    </div>
  </div>
);

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  modalState: PropTypes.instanceOf(Object).isRequired,
  modalName: PropTypes.string.isRequired,
  setModalState: PropTypes.func.isRequired,
};

export default Modal;
