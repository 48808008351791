import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Quote.scss';
import Button from '../../atoms/Button/Button';
import Modal from '../Modal/Modal';
import UnitImages from '../UnitImages/UnitImages';
import UnitInformation from '../UnitInformation/UnitInformation';
import Card from '../../atoms/Card/Card';
import { UNIT_STATUSES } from '../../../appConstants';
import { formatDateFromSFDC } from '../../../helpers/utils/generic';
import { getUnitImages } from '../../../api/storage';

const Quote = ({
  unit,
  unit: {
    quotes,
    unitStatus,
    unitName,
    estate,
    monthlyRent,
    premium,
    bedrooms,
    moveInEnd,
    moveInStart,
    imageUrl,
    wishList,
    furnished,
  },
  selected,
  index,
  setSelectedUnitIndex,
  resetQuote,
}) => {
  const [modalState, setModalState] = useState({
    seeMore: false,
    seeWishList: false,
  });
  const unitUnavailable = !UNIT_STATUSES.includes(unitStatus);
  const moveInFirstDate = moveInStart && formatDateFromSFDC(moveInStart);
  const moveInEndDate = moveInEnd && formatDateFromSFDC(moveInEnd);
  const premiumBanner = premium === 'Premium';
  const onClickChooseHome = () => {
    setSelectedUnitIndex(index);
    resetQuote();
  };
  const [imageKeys, setImageKeys] = useState({
    images: [],
    floorplanUrl: undefined,
    epcUrl: undefined,
  });

  useEffect(() => {
    async function fetchData() {
      if (imageUrl) {
        getUnitImages(imageUrl).then((signedKeys) => {
          const floorplanUrl = signedKeys.filter((file) => file.match(/floorplan.pdf/i));
          const epcUrl = signedKeys.filter((file) => file.match(/epc.pdf/i));
          setImageKeys({
            images: signedKeys.filter((file) => file.match(/\.(jpg|png|gif)/)),
            floorplanUrl: floorplanUrl[0],
            epcUrl: epcUrl[0],
          });
        });
      }
    }
    fetchData();
  }, [imageUrl]);

  return (
    <div
      className={`quote ${selected ? 'quote--selected' : ''}`}
      disabled={unitUnavailable}
      data-testid="quote"
    >
      <Card>
        <UnitImages images={imageKeys.images} />
        { unitUnavailable
          && <div className="quote__banner">This home is no longer available</div>}

        <div className="quote__content">
          {premiumBanner && (
            <p className="unit-attribute premium" data-testid="quote-premium">
              {premium}
            </p>
          )}
          <h2
            data-testid="quote-unit-name"
            dangerouslySetInnerHTML={{ __html: unitName }}
          />
          <h3
            data-testid="quote-estate-name"
            dangerouslySetInnerHTML={{ __html: estate }}
          />
          <p className="unit-attribute">
            {`£${monthlyRent}/month`}
          </p>
          <p className="unit-attribute">
            { furnished }
          </p>
          <p className="unit-attribute">
            { bedrooms }
          </p>
          <Button
            title="See more"
            onClick={() => setModalState({ ...modalState, seeMore: true })}
            disabled={unitUnavailable}
            plain
          />
          {modalState.seeMore && (
            <Modal
              modalState={modalState}
              modalName="seeMore"
              setModalState={setModalState}
            >

              <h3
                data-testid="quote-estate-name"
                dangerouslySetInnerHTML={{ __html: estate }}
              />
              { imageKeys.floorplanUrl
                && (
                <>
                  <a
                    href={imageKeys.floorplanUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button button--plain"
                  >
                    See Floorplan
                  </a>
                  <br />
                  <br />
                </>

                )}
              { imageKeys.epcUrl
                && (
                <a
                  href={imageKeys.epcUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button button--plain"
                >
                  See EPC certificate
                </a>
                )}

              <UnitInformation {...unit} />
            </Modal>
          )}
          <div className="quote__terms">
            <h4>Terms available:</h4>
            <p className="unit-attribute">
              {quotes.map((x) => (
                <span key={x.term} className="quote__term">
                  {x.term}
                </span>
              ))}
            </p>
            <h4>Move-in dates available:</h4>
            {moveInFirstDate && moveInEndDate && (
              <p className="quote__term">
                {`${moveInFirstDate} - ${moveInEndDate}`}
              </p>
            )}
            {wishList && (
              <div className="quote__wishlist">
                <h4>Wishlist in place:</h4>
                <Button
                  title="See wishlist"
                  plain
                  onClick={() => setModalState({ ...modalState, seeWishList: true })}
                  disabled={unitUnavailable}
                />

                {modalState.seeWishList && (
                  <Modal
                    modalState={modalState}
                    modalName="seeWishList"
                    setModalState={setModalState}
                  >
                    <p className="unit-attribute">
                      <span className="quote__term">
                        {wishList}
                      </span>
                    </p>
                  </Modal>
                )}
              </div>
            )}
          </div>

          <div className="quote__button">
            <Button
              title="Choose this home"
              disabled={unitUnavailable}
              onClick={() => onClickChooseHome()}
              testId="choose-this-home"
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

Quote.propTypes = {
  unit: PropTypes.shape({
    unitName: PropTypes.string.isRequired,
    quotes: PropTypes.instanceOf(Array).isRequired,
    unitStatus: PropTypes.string.isRequired,
    estate: PropTypes.string.isRequired,
    monthlyRent: PropTypes.number.isRequired,
    premium: PropTypes.string,
    floorLevel: PropTypes.string,
    moveInStart: PropTypes.string,
    moveInEnd: PropTypes.string,
    imageUrl: PropTypes.string,
    wishList: PropTypes.string,
    furnished: PropTypes.string,
    bedrooms: PropTypes.string,
  }),
  bedrooms: PropTypes.string,
  bathrooms: PropTypes.string,
  buildingAmenities: PropTypes.string,
  petFriendly: PropTypes.bool,
  unitAmenities: PropTypes.instanceOf(Array),
  selected: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  setSelectedUnitIndex: PropTypes.func.isRequired,
  resetQuote: PropTypes.func.isRequired,
  flatNo: PropTypes.string,
  streetNo: PropTypes.string,
  streetName: PropTypes.string,
  city: PropTypes.string,
  postCode: PropTypes.string,
  outdoorSpace: PropTypes.string,
  aspect: PropTypes.string,
  internalSpaceFt: PropTypes.number,
  keyFeatures: PropTypes.string,
  accessibility: PropTypes.string,
};

Quote.defaultProps = {
  unit: {
    wishList: null,
    premium: null,
    moveInStart: null,
    moveInEnd: null,
    floorLevel: null,
    imageUrl: null,
    furnished: null,
    bedrooms: null,
  },
  petFriendly: null,
  city: null,
  internalSpaceFt: null,
  flatNo: null,
  streetNo: null,
  streetName: null,
  accessibility: '',
  aspect: null,
  bedrooms: null,
  bathrooms: null,
  keyFeatures: null,
  postCode: null,
  outdoorSpace: null,
  buildingAmenities: null,
  unitAmenities: null,
};

export default Quote;
