import React, { useContext } from 'react';
import { Authenticator, SignUp } from 'aws-amplify-react';
import { AuthContext } from './providers/AuthContext';
import StageRedirection from './components/Routing/StageRedirection';
import './AppWithAuth.scss';

const theme = {
  button: { backgroundColor: '#62B1AC' },
  a: { color: '#62B1AC' },
  sectionContainer: {
    marginTop: '68px',
  },
  formContainer: {
    margin: '0',
  },
  toast: {
    backgroundColor: 'rgb(234, 102, 65)',
    position: 'static',
  },
  formSection: {
    width: '100%',
    minWidth: '300px',
    maxWidth: '460px',
    margin: '0',
    boxShadow: 'none',
    marginBottom: '0',
  },
};

const AppWithAuth = () => {
  const { authenticated, setAuth } = useContext(AuthContext);

  if (authenticated) {
    return (
      <StageRedirection />
    );
  }
  return (
    <div className="custom-auth__margin">
      <div className="custom-auth--other">
        <div className="custom-auth__center">
          <div className="custom-auth__title">
            grainger
            <span className="custom-auth__title-text">plc</span>
          </div>
          <Authenticator
            authState="signIn"
            hide={[SignUp]}
            usernameAttributes="email"
            theme={theme}
            onStateChange={(authState, data) => setAuth({
              authenticated: authState === 'signedIn',
              attributes: data && data.attributes,
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default AppWithAuth;
