import React from 'react';
import PropTypes from 'prop-types';
import './RadioInput.scss';

const RadioInput = ({
  name, label, id, className, value, centered,
}) => (
  <div className={`radioInput ${className}`} data-testid="radio-input">
    <input
      type="radio"
      id={id}
      className="radioInput__radio"
      name={name}
      value={value}
    />
    <label
      className="radioInput__label"
      htmlFor={id}
      data-testid={id}
    >
      <span className="radioInput__radio--custom" />
      <span className={`radioInput__text radioInput__text${centered ? '--centered' : ''}`}>
        {label}
      </span>
    </label>
  </div>
);

RadioInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  centered: PropTypes.bool,
};

RadioInput.defaultProps = {
  name: 'radio',
  label: '',
  id: null,
  className: '',
  value: '',
  centered: false,
};

export default RadioInput;
