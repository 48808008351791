import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '../../atoms/Button/Button';
import TextInput from '../../atoms/TextInput/TextInput';
import TitleHeader from '../TitleHeader/TitleHeader';
import Card from '../../atoms/Card/Card';
import directDebitFormDefinition from '../../../config/directDebitFormDefinition';
import { UserContext } from '../../../providers/UserContext';
import { updateOpportunityContactRole } from '../../../api/salesforce';
import './DirectDebitForm.scss';

const DirectDebitForm = ({ title, testId, ddAmount }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [amountEntity, setAmountEntity] = useState(ddAmount);
  const { ocrId } = useContext(UserContext);
  const {
    id,
    label,
    type,
    validation,
    onBlurMessage,
  } = directDebitFormDefinition;
  const { register, errors, handleSubmit } = useForm();
  const [isBlur, setIsBlur] = useState(false);

  const onSubmit = ({ amount }) => {
    updateOpportunityContactRole(ocrId, { amount });
    setAmountEntity(amount);
    setIsEditing(false);
  };

  return (
    <div className="directDebitForm" data-testid={testId}>
      <Card>
        <TitleHeader category={title} />
        {amountEntity && !isEditing ? (
          <div className="directDebitForm__container row">
            <span className="directDebitForm__label col-5">{label}</span>
            <span
              className="directDebitForm__value col-5"
              data-testid="directDebitForm__value"
            >
              {`£${amountEntity}`}
            </span>
            <Button
              type="button"
              title="Edit"
              onClick={() => setIsEditing(true)}
              testId="editDirectDebitAmount"
              plain
            />
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="directDebitForm__container">
              <TextInput
                id={id}
                label={label}
                type={type}
                testId={id}
                register={register}
                validation={validation}
                hasError={errors[id]}
                value={amountEntity}
                onBlur={() => setIsBlur(true)}
                isBlur={isBlur}
                onBlurMessage={onBlurMessage}
              />
              <Button
                type="submit"
                title="Save"
                testId="saveDirectDebitAmount"
                primary
              />
            </div>
          </form>
        )}
      </Card>
    </div>
  );
};

DirectDebitForm.propTypes = {
  ddAmount: PropTypes.number,
  title: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
};

DirectDebitForm.defaultProps = {
  ddAmount: null,
};

export default DirectDebitForm;
