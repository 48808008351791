import React from 'react';
import './SocialLinks.scss';
import List from '../../atoms/List/List';

const social = [
  { icon: 'facebook', link: 'https://www.facebook.com/Graingerplc/', text: 'Facebook' },
  { icon: 'twitter', link: 'https://twitter.com/graingerplc', text: 'Twitter' },
  { icon: 'instagram', link: 'https://www.instagram.com/graingerplc/', text: 'Instagram' },
];

const SocialLinks = () => (
  <div>
    {social
      && (
        <List className="social-links" isInline isUnstyled>
          {social.map((link) => (
            <li key={link.icon}>
              <a
                className="social-link"
                href={link.link}
                title={link.text}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">{link.text}</span>
                <i className={`icon icon-${link.icon}`} aria-hidden="true" />
              </a>
            </li>
          ))}
        </List>
      )}
  </div>
);
export default SocialLinks;
