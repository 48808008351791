import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../../providers/UserContext';
import { BasketContext } from '../../providers/BasketContext';
import {
  ROLE_REFERENCING_STATUSES,
  OPPORTUNITY_REFERENCING_STATUSES,
  SF_ROLES,
} from '../../appConstants';
import {
  PATHS,
  LEAD_TENANT_JOURNEY,
  COMMON_JOURNEY,
  GUARANTOR_JOURNEY,
  COMMON_RENEWAL_JOURNEY,
  GUARANTOR_RENEWAL_JOURNEY,
} from '../../navigationConstants';

const StageRedirection = () => {
  const {
    opportunityStage,
    referencingStatus,
    paymentPeriodicity,
    isReferencingExempt,
    isRenewal,
    role,
  } = useContext(UserContext);

  const { basketData: { periodicity } } = useContext(BasketContext);

  const referencingStatuses = [
    ROLE_REFERENCING_STATUSES.COMPLETED,
    ROLE_REFERENCING_STATUSES.IN_PROGRESS,
  ];
  let stages;
  switch (role) {
    case SF_ROLES.Guarantor:
      stages = isRenewal ? GUARANTOR_RENEWAL_JOURNEY : GUARANTOR_JOURNEY;
      break;
    case SF_ROLES.LeadTenant:
      stages = isRenewal ? COMMON_RENEWAL_JOURNEY : LEAD_TENANT_JOURNEY;
      break;
    default:
      stages = isRenewal ? COMMON_RENEWAL_JOURNEY : COMMON_JOURNEY;
      break;
  }

  if (OPPORTUNITY_REFERENCING_STATUSES.includes(opportunityStage)) {
    if (isReferencingExempt || referencingStatuses.includes(referencingStatus)) {
      return <Redirect to={stages.ON_REFERENCING} />;
    }
    return <Redirect to={stages.REFERENCES_REQUESTED} />;
  }

  // Little tampering for the navigation path regardless of role or tenancy type.
  if ((stages[opportunityStage] === PATHS.DIRECT_DEBIT) && paymentPeriodicity && paymentPeriodicity !== 'Monthly') {
    return <Redirect to={PATHS.APPLICATION_COMPLETE} />;
  }

  return <Redirect to={stages[opportunityStage]} />;
};

export default StageRedirection;
