import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { maxDate, minDate, formatDate } from '../../../helpers/utils/generic';
import './datepicker.scss';

const Picker = ({
  size, breakpoint, setMoveInDate, moveInStart, moveInEnd,
}) => {
  const moveInStartDate = new Date(moveInStart);
  const minMoveInDate = minDate(new Date());
  const startDate = moveInStartDate > minMoveInDate ? moveInStartDate : minMoveInDate;
  const [chosenDate, setChosenDate] = useState();

  const moveInEndDate = new Date(moveInEnd);
  const maxMoveInDate = maxDate(new Date());
  const endDate = moveInEndDate < maxMoveInDate && moveInEndDate >= minMoveInDate
    ? moveInEndDate : maxMoveInDate;

  const changeDate = (date) => {
    setChosenDate(date);
    setMoveInDate(formatDate(date));
  };
  return (
    <div className="datepicker">
      <DatePicker
        selected={chosenDate}
        onChange={(date) => { changeDate(date); }}
        inline
        maxDate={endDate}
        minDate={startDate}
        nextMonthButtonLabel=""
        previousMonthButtonLabel=""
        monthsShown={size < breakpoint ? 1 : 2}
      />
    </div>
  );
};

Picker.propTypes = {
  size: PropTypes.number,
  breakpoint: PropTypes.number,
  moveInStart: PropTypes.string,
  moveInEnd: PropTypes.string,
  setMoveInDate: PropTypes.func.isRequired,
};

Picker.defaultProps = {
  size: 900,
  breakpoint: 700,
  moveInStart: null,
  moveInEnd: null,

};

export default Picker;
