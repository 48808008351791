import React from 'react';
import PropTypes from 'prop-types';
import Footer from './components/molecules/Footer/Footer';
import Header from './components/molecules/Header/Header';
import Container from './components/atoms/Container/Container';

const ErrorLayout = ({ children }) => {
  window.scrollTo(0, 0);

  return (
    <>
      <Header />
      <Container>
        <div className="mt-5">
          { children }
        </div>
      </Container>
      <Footer
        social={[]}
      />
    </>
  );
};

ErrorLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorLayout;
