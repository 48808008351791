import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import './StatusTile.scss';
import { ROLE_REFERENCING_STATUSES } from '../../../appConstants';
import { formatReferencingStatus } from '../../../helpers/utils/generic';
import { referencingInfoCopy } from '../../../content/main.json';
import { WalesContext } from '../../../providers/WalesContext';

const StatusTile = ({
  role,
  status,
  firstName,
  lastName,
  isReferencingExempt,
}) => {

  const { isWales } = useContext(WalesContext);

  const { referencingExemptCopy } = !isWales ? referencingInfoCopy : referencingInfoCopy.wales;
  const showTick = isReferencingExempt || status === ROLE_REFERENCING_STATUSES.COMPLETED;
  const statusCopy = isReferencingExempt ? referencingExemptCopy : status && `${formatReferencingStatus(status)}`;

  return (
    <div className="statusTile" data-testid="statusTile">
      <h2 className="statusTile__heading">
        {`${!isWales ? role : (role || '').replace('Tenant','Contract Holder')}`}
      </h2>
      <div className="statusTile__name">{`${firstName} ${lastName}`}</div>
      <div className="statusTile__icon">
        <i className={`icon ${showTick ? 'icon-check' : 'icon-loop2'}`} aria-hidden="true" />
      </div>
      <div className="statusTile__status" data-testid="statusTile_status">{statusCopy}</div>
    </div>
  );
};

StatusTile.propTypes = {
  role: PropTypes.string.isRequired,
  status: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  isReferencingExempt: PropTypes.bool,
};

StatusTile.defaultProps = {
  status: null,
  isReferencingExempt: false,
};

export default StatusTile;
