/* eslint-disable no-console */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { ContactRoleContext } from '../../../providers/ContactRoleContext';
import { updatePhoneNumber } from '../../../api/salesforce';
import TextInput from '../../atoms/TextInput/TextInput';
import SavedInput from '../../atoms/SavedInput/SavedInput';
import TitleHeader from '../TitleHeader/TitleHeader';
import Button from '../../atoms/Button/Button';
import Card from '../../atoms/Card/Card';
import { UserContext } from '../../../providers/UserContext';

const LeadTenantForm = ({ copy, fields }) => {
  const { register, errors, handleSubmit } = useForm();
  const { contactId } = useContext(UserContext);

  const { householdData, setHouseholdData } = useContext(ContactRoleContext);
  const { LeadTenant: LeadTenantArray } = householdData;
  const LeadTenant = LeadTenantArray[0];

  const onSubmit = (data) => {
    updatePhoneNumber(contactId, data.phoneNumber);
    const tempArr = { ...householdData };
    const phoneObject = {
      id: fields[3].id,
      label: fields[3].label,
      value: data.phoneNumber,
    };
    const tempLeadTenant = tempArr.LeadTenant[0];
    tempLeadTenant.fields.push(phoneObject);
    tempArr.LeadTenant.splice(0, 1, tempLeadTenant);
    setHouseholdData({ ...tempArr });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card>
        <TitleHeader category={copy.heading} />
        {LeadTenant && LeadTenant.fields && LeadTenant.fields.map((field) => {
          if (field.value) {
            return (
              <SavedInput
                key={uuid()}
                id={field.id}
                label={field.label}
                value={field.value}
              />
            );
          }
          return null;
        })}
        {(LeadTenant && LeadTenant.fields && LeadTenant.fields.length < 4) && (
          <>
            <div className="leadTenantForm row">
              <TextInput
                label={fields[3].label}
                type={fields[3].type}
                id={fields[3].id}
                required
                testId="leadTenantPhone"
                className="col-lg-6"
                register={register}
                validation={fields[3].validation}
                hasError={errors[fields[3].id]}
              />
            </div>
            <Button type="submit" title="Save" testId="savePhoneNumber" primary />
          </>
        )}
      </Card>
    </form>
  );
};

LeadTenantForm.propTypes = {
  copy: PropTypes.instanceOf(Object).isRequired,
  fields: PropTypes.arrayOf(Object).isRequired,
};

export default LeadTenantForm;
