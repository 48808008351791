/* eslint-disable react/no-danger */
import React from 'react';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import { renewalConfirmed } from '../../content/main.json';

const { title, content } = renewalConfirmed;
const RenewalConfirmed = () => (
  <>
    <TitleHeader title={title} large />
    {content}
  </>
);

export default RenewalConfirmed;
