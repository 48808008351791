import React from 'react';
import PropTypes from 'prop-types';

import './Img.scss';

const Img = ({
  src, alt, isFluid, className,
}) => {
  const classes = ['img', className];
  if (isFluid) {
    classes.push('img--fluid');
  }
  return <img src={src} alt={alt} className={classes.join(' ')} />;
};

Img.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  isFluid: PropTypes.bool,
  className: PropTypes.string,
};

Img.defaultProps = {
  src: null,
  alt: '',
  isFluid: false,
  className: null,
};

export default Img;
