import React from 'react';
import PropTypes from 'prop-types';

const UnitInformation = ({
  unitAmenities,
  floorLevel,
  bedrooms,
  bathrooms,
  petFriendly,
  buildingAmenities,
  flatNo,
  streetNo,
  streetName,
  city,
  postCode,
  internalSpaceFt,
  outdoorSpace,
  aspect,
  keyFeatures,
  accessibility,
}) => {
  const additionalInfo = [
    { Bedrooms: bedrooms },
    { Bathrooms: bathrooms && `${bathrooms} Bathrooms` },
    { FloorLevel: floorLevel && `Floor: ${floorLevel} ` },
    { KeyFeatures: keyFeatures && `Key Features: ${keyFeatures}` },
    { PetFriendly: petFriendly && 'Pet friendly' },
    { Aspect: aspect && `${aspect} aspect` },
    { InternalSpace: internalSpaceFt ? `Internal Space: ${internalSpaceFt} ft` : '' },
    { OutdoorSpace: outdoorSpace },
    { Accessibility: accessibility },
    { UnitAmenities: unitAmenities.join(', ') },
  ];

  const address = [
    { FlatNo: flatNo },
    { StreetNo: streetNo },
    { StreetName: streetName },
    { City: city },
    { PostCode: postCode },
  ];

  return (
    <div className="quote__limit row">
      <div className="col-lg-4">
        <h5>Address</h5>
        {address.map((unitAddress) => Object.values(unitAddress)[0] && (
          <p key={unitAddress}>
            {Object.values(unitAddress)}
          </p>
        ))}
      </div>
      <div className="col-lg-4">
        <h5>Features</h5>
        {additionalInfo.map((unitFeatures) => Object.values(unitFeatures)[0] && (
          <p key={unitFeatures}>
              {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: Object.values(unitFeatures) }} />
          </p>
        ))}
      </div>
      <div className="col-lg-4">
        <h5>Building Amenities</h5>
        {typeof buildingAmenities === 'string'
          && buildingAmenities.length
          && buildingAmenities.split(';').map(
            (amenity) => Object.values(amenity)[0] && (
              <p className="unit-attribute" key={amenity}>
                {Object.values(amenity)}
              </p>
            ),
          )}
      </div>
    </div>
  );
};

UnitInformation.propTypes = {
  bedrooms: PropTypes.string,
  bathrooms: PropTypes.string,
  buildingAmenities: PropTypes.string.isRequired,
  petFriendly: PropTypes.bool,
  unitAmenities: PropTypes.instanceOf(Array).isRequired,
  floorLevel: PropTypes.string,
  flatNo: PropTypes.string,
  streetNo: PropTypes.string,
  streetName: PropTypes.string,
  city: PropTypes.string,
  postCode: PropTypes.string,
  outdoorSpace: PropTypes.string,
  aspect: PropTypes.string,
  internalSpaceFt: PropTypes.number,
  keyFeatures: PropTypes.string,
  accessibility: PropTypes.string,
};

UnitInformation.defaultProps = {
  petFriendly: null,
  city: null,
  internalSpaceFt: null,
  floorLevel: null,
  flatNo: null,
  streetNo: null,
  streetName: null,
  accessibility: '',
  aspect: null,
  bedrooms: null,
  bathrooms: null,
  keyFeatures: null,
  postCode: null,
  outdoorSpace: null,
};

export default UnitInformation;
