/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import './Footer.scss';
import Img from '../../atoms/Img/Img';
import List from '../../atoms/List/List';
import logo from '../../../assets/images/grainger-logo-white.png';
import { footerCopy } from '../../../content/main.json';

const Footer = (props) => {
  const {
    social,
  } = props;
  return (
    <footer className="gr-footer">
      <div className="container">
        <div className="row gr-footer__row">
          <div className="col-md-7 col-lg-8">
            {social
                && (
                <List className="gr-footer__social-links" isInline isUnstyled>
                  {social.map((link) => (
                    <li key={link.icon}>
                      <a
                        className="gr-footer__social-link"
                        href={link.link}
                      >
                        <span className="sr-only">{link.text}</span>
                        <i className={`icon icon-${link.icon}`} aria-hidden="true" />
                      </a>
                    </li>
                  ))}
                </List>
                )}

            <Img alt="grainger plc" src={logo} className="gr-footer__logo" />

            <p className="gr-footer__grainger-info">{footerCopy.strapline}</p>
          </div>

          <div className="col-md-5 col-lg-4">
            { footerCopy.links.map((link) => (
              <a
                key={uuid()}
                href={link.url}
                className="gr-footer__page-link"
              >
                {link.linkText}
              </a>
            ))}

            <a href={`tel:${footerCopy.phoneNumber}`} className="gr-footer__phone-number">{footerCopy.phone}</a>
            <div className="gr-footer__text">{footerCopy.info}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};


Footer.propTypes = {
  social: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    link: PropTypes.string,
  })),
};

Footer.defaultProps = {
  social: null,
};

export default Footer;
