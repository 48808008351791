import { breakClauseCopy, referencingStatusCopy } from '../../content/main.json';

const isMobileView = () => window.innerWidth <= 768;
const isNotMobileView = () => !isMobileView();

const isEnterKey = (key) => (key === 13 || key === 'Enter');

/**
 * Generate a random string. Mainly used for generating unique ids
 * @param   {string}  str   This input will be the prefix of the random string returned
 *
 * @returns {string}
 */

const randomStr = (str = '') => {
  if (str && str.length) {
    return `${str.replace(/ +/g, '-')}-${Math.random().toString(36).substr(2, 10)}`.toLowerCase();
  }

  return Math.random().toString(36).substr(2, 10);
};

const extractAsperatoIframeURL = (str) => {
  const hrefRegex = /<a[^>]* href="([^"]*)"/g;
  const extractedUrl = hrefRegex.exec(str)[1];
  return extractedUrl.replace(/&amp;/g, '&');
};

const numberTo2dp = (num) => (
  num !== undefined && Math.abs(Number(num)).toFixed(2)
) || '0.00';

const isNumber = (value) => !Number.isNaN(parseFloat(value));
const isPositiveNumber = (time) => (isNumber(time) && Number(time) > 0);

const replaceSpaces = (str, symbol) => str.replace(/\s/g, symbol);

const maxDate = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const returnedDate = new Date(year, month + 6, day + 3);
  return returnedDate;
};

const minDate = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const returnedDate = new Date(year, month, day + 3);
  return returnedDate;
};

const formatDate = (date) => {
  const day = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
  const month = `${(date.getMonth()) < 9 ? '0' : ''}${date.getMonth() + 1}`;
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

const formatDateFromSFDC = (date) => date.split('-').reverse().join('/');

const formatBreakClause = (breakClauseStr) => {
  const finalBreakClauseStr = breakClauseStr || '';
  const breakClauseObject = breakClauseCopy.find(
    (item) => item.sfBreakClause === finalBreakClauseStr,
  );
  return breakClauseObject && breakClauseObject.webBreakClause;
};

const formatReferencingStatus = (referencingStatusStr) => {
  // eslint-disable-next-line max-len
  const statusObject = referencingStatusCopy.find(
    (item) => item.sfReferencingStatus === referencingStatusStr,
  );
  return statusObject && statusObject.webReferencingStatus;
};
export {
  isMobileView,
  isNotMobileView,
  isNotMobileView as isDesktopView,
  isEnterKey,
  randomStr,
  isNumber,
  isPositiveNumber,
  maxDate,
  minDate,
  formatDate,
  formatDateFromSFDC,
  replaceSpaces,
  formatReferencingStatus,
  formatBreakClause,
  numberTo2dp,
  extractAsperatoIframeURL,
};
