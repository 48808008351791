import React from 'react';
import PropTypes from 'prop-types';
import './CheckboxInput.scss';

const CheckboxInput = ({
  name, label, id, className, value, onChange,
}) => (
  <label className={`checkboxInput ${className}`} htmlFor={id}>
    <input type="checkbox" id={id} className="checkboxInput__checkbox" name={name} value={value} onChange={onChange} />
    <span className="checkboxInput__checkbox--custom" />
    <span className="checkboxInput__label">{label}</span>
  </label>
);

CheckboxInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

CheckboxInput.defaultProps = {
  name: 'checkbox',
  label: '',
  id: null,
  className: '',
  value: '',
};

export default CheckboxInput;
