export default function (token) {
  // TODO: add decode step if token is encoded
  const delimiter = '~';
  if (token) {
    const [email, duoId, role, type] = token.split(delimiter);
    return [
      email,
      duoId && duoId.slice(0, duoId.length / 2),
      duoId && duoId.slice(-duoId.length / 2),
      role && role.replace(/_/g, ' '),
      type,
    ];
  }
  return [];
}
