import React from 'react';
import PropTypes from 'prop-types';

import './List.scss';

const List = ({
  isOrdered, isInline, isUnstyled, children, className,
}) => {
  const classes = ['list'];

  if (isInline) {
    classes.push('list--inline');
  }

  if (isUnstyled) {
    classes.push('list--unstyled');
  }

  classes.push(className);

  return isOrdered ? (
    <ol className={classes.join(' ')}>
      {children}
    </ol>
  ) : (
    <ul className={classes.join(' ')}>
      {children}
    </ul>
  );
};

List.propTypes = {
  isInline: PropTypes.bool,
  isUnstyled: PropTypes.bool,
  isOrdered: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  className: PropTypes.string,
};

List.defaultProps = {
  children: null,
  isInline: false,
  isUnstyled: false,
  isOrdered: false,
  className: null,
};

export default List;
