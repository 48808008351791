/* eslint-disable react/no-danger */
import React from 'react';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import { applicationComplete } from '../../content/main.json';

const { title, content } = applicationComplete;

const ApplicationComplete = () => (
  <>
    <TitleHeader title={title} large />
    <p dangerouslySetInnerHTML={{ __html: content }} />
  </>
);

export default ApplicationComplete;
