import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import Loading from '../../components/atoms/Loading/Loading';
import ErrorBanner from '../../components/molecules/ErrorBanner/ErrorBanner';
import { PATHS } from '../../navigationConstants';
import { getOcrReferencing } from '../../api/salesforce';
import { referencingInfoCopy } from '../../content/main.json';
import { UserContext } from '../../providers/UserContext';
import { WalesContext } from '../../providers/WalesContext';
import Button from '../../components/atoms/Button/Button';
import './Referencing.scss';

const Referencing = ({ history }) => {
  const { isWales } = useContext(WalesContext);
  let referencingInfoTerms = !isWales ? referencingInfoCopy : referencingInfoCopy.wales;
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState(null);
  const [provider, setProvider] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formTimeout, setFormTimeout] = useState(false);
  const [titleCopy, setTitleCopy] = useState(referencingInfoTerms.title);
  const { opportunityId, contactId } = useContext(UserContext);

  const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  const vanMildertListener = (e) => {
    if (url && url.includes(e.origin)) {
      if (e.data && e.data.callback) {
        if (e.data.callback === 'referenceApplicationDone') {
          document.getElementById('referencing-iframe').classList.add('referencing__iframe--submit');
          setFormSubmitted(true);
          setTitleCopy(referencingInfoTerms.successTitle);
        }
        if (e.data.callback === 'referenceApplicationLoggedOut') {
          setFormTimeout(true);
        }
      }
    }
  };

  window.addEventListener('message', vanMildertListener);

  useEffect(() => {
    const fetchUrl = async() => {     
      const retry_appliant_id = true
      const { referencingUrl, referenceProvider, access_token, error } = await getOcrReferencing(opportunityId, contactId, retry_appliant_id);
      if ((referenceProvider || '').toLowerCase() !== 'homelet') {
        if (!referencingUrl) {
          return history.push(PATHS.ERROR_REFERENCING_UNAVAILABLE);
        }
        setUrl(referencingUrl);
        setProvider('rightmove');
        setAccessToken(null);
      } else {
        console.log('error:', error)
        if(error) {
          if (error === 'INVALID_APPLICANT_USER_ID') {
            return history.push(PATHS.ERROR_REFERENCING_INVALID_APPLICANT);
          } else {
            return history.push(PATHS.ERROR);
          }
          
        }
        if (!(process.env.REACT_APP_HOMELET_ENDPOINT)) {
          return history.push(PATHS.ERROR_REFERENCING_UNAVAILABLE);
        }
        setUrl(`${process.env.REACT_APP_HOMELET_ENDPOINT}/tenanatautologin?accesscode=${access_token}`)
        setProvider('homelet');
        setAccessToken(access_token);
      }

      return setLoading(false);
    }
    fetchUrl();
  }, []);

  const handleIframeLoad = () => {
    return;
    /* if (accessToken) {
      const iframe = document.getElementById('referencing-iframe');
      if (isSafari()) {
        console.log('from safari...');
        const targetOrigin = process.env.REACT_APP_HOMELET_ENDPOINT;
        console.log(accessToken, targetOrigin);
        iframe.contentWindow.postMessage(accessToken, targetOrigin);
      } else {
        console.log('from non saffari...');
        console.log(accessToken);
        iframe.contentWindow.postMessage(accessToken, '*');
      }
    } */
  }

  return (
    <>

      <TitleHeader
        title={titleCopy}
        category={referencingInfoTerms.category}
        large
      />
      {loading && <Loading />}
      {formTimeout && (
        <ErrorBanner
          body={['Sorry this page has expired. Please refresh the page.']}
        />
      )}

      {!loading && !formTimeout
        && (
          <div className="row referencing__iframe-container">
            <iframe
              className="col-12 referencing__iframe"
              title="referencing-iframe"
              src={url}
              id="referencing-iframe"
              name="iframeTenantPortal"
              onLoad={handleIframeLoad}
            />
          </div>
        )}
      {formSubmitted
        && (


          <Link to={PATHS.REFERENCING_PROGRESS}>
            <Button title={referencingInfoTerms.nextButton} />
          </Link>
        )}
    </>
  );
};
Referencing.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default Referencing;
