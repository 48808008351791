import React, { useState } from 'react';
import { Auth } from 'aws-amplify';

import './Menu.scss';
import { v4 as uuid } from 'uuid';
import Container from '../../atoms/Container/Container';
import Button from '../../atoms/Button/Button';
import { documents } from '../../../content/main.json';
import SocialLinks from '../SocialLinks/SocialLinks';

const Menu = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div>
      <div data-testid="overlay" className="menu" style={showMenu ? { height: '100%' } : { height: '0%' }}>
        <Container>
          <div className="menu__header">
            Grainger
            <span>PLC</span>
            <Button
              type="button"
              title="&times;"
              testId="closeIcon"
              className="navigation-documents-button menu__close"
              onClick={() => setShowMenu(false)}
              plain
            />
          </div>
          <div className="menu__content">
            <ul className="menu__list">
              {documents.map((item) => (
                <li key={uuid()} data-testid="href-links">
                  <a href={item.ref} target="_blank" title={item.title} rel="noopener noreferrer">{item.title}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="menu__socials">
            <SocialLinks />
          </div>
        </Container>
      </div>
      <div className="menu_buttons">
        <Button
          data-testid="documentTest"
          type="button"
          title="Documents"
          className="navigation-documents-button"
          onClick={() => setShowMenu(true)}
          secondary
        />
        <Button
          data-testid="logoutTest"
          type="button"
          title="LOG OUT"
          className="navigation-logout-button"
          plain
          onClick={() => {
            Auth.signOut()
              .then((data) => console.log(data)) // eslint-disable-line no-console
              .catch((err) => console.log(err)); // eslint-disable-line no-console
          }}
        />
      </div>
    </div>
  );
};

export default Menu;
