import { fieldAttributes } from '../content/main.json';

const {
  firstName, lastName, email, phoneNumber, relationship, other, dob,
} = fieldAttributes;

const householdFormDefinition = {
  leadTenantFields: [
    {
      label: firstName.label,
      id: firstName.id,
      type: 'text',
      disabled: true,
    },
    {
      label: lastName.label,
      id: lastName.id,
      type: 'text',
      disabled: true,
    },
    {
      label: email.label,
      id: email.id,
      type: 'email',
      disabled: true,
    },
    {
      label: phoneNumber.label,
      id: phoneNumber.id,
      type: 'text',
      required: true,
      validation: {
        required: 'Please enter a phone number',
        pattern: {
          value: /^\d+$/,
          message: 'Invalid phone number',
        },
      },
    },
  ],
  additionalTenantFields: [
    {
      label: firstName.label,
      type: 'text',
      id: firstName.id,
      required: true,
      validation: {
        required: 'Please enter first name',
      },
    },
    {
      label: lastName.label,
      type: 'text',
      id: lastName.id,
      required: true,
      validation: {
        required: 'Please enter last name',
      },
    },
    {
      label: email.label,
      id: email.id,
      type: 'email',
      required: true,
      validation: {
        required: 'Please enter an email address',
        pattern: {
          value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Invalid email pattern',
        },
      },
    },
    {
      label: phoneNumber.label,
      id: phoneNumber.id,
      type: 'text',
      required: true,
      validation: {
        required: 'Please enter a phone number',
        pattern: {
          value: /^\d+$/,
          message: 'Invalid phone number',
        },
      },
    },
  ],
  additionalOccupantFields: [
    {
      label: firstName.label,
      id: firstName.id,
      type: 'text',
      required: true,
      validation: {
        required: 'Please enter first name',
      },
    },
    {
      label: lastName.label,
      id: lastName.id,
      type: 'text',
      required: true,
      validation: {
        required: 'Please enter last name',
      },
    },

    {
      label: relationship.label,
      id: relationship.id,
      type: 'select',
      testId: 'select-test',
      required: true,
      validation: {
        required: 'Please select an option',
      },
      options: [{ value: 'Spouse' }, { value: 'Partner' }, { value: 'Company Employee' }, { value: 'Child' }, { value: 'Other' }],
    },
  ],
  extensionFields: [
    {
      label: other.label,
      id: other.id,
      selector: 'Other',
      type: 'text',
      required: true,
      validation: {
        required: 'Please fill in this field',
      },
    },
    {
      label: dob.label,
      id: dob.id,
      selector: 'Child',
      type: 'date',
      required: true,
      validation: {
        required: 'Please enter the Date Of Birth',
      },
    },
    {
      label: email.label,
      id: email.id,
      selector: 'Company Employee',
      type: 'text',
      required: true,
      validation: {
        required: 'Please enter an email address',
        pattern: {
          value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Invalid email pattern',
        },
      },
    },
  ],
  guarantorFields: [
    {
      label: firstName.label,
      id: firstName.id,
      type: 'text',
      required: true,
      validation: {
        required: 'Please enter first name',
      },
    },
    {
      label: lastName.label,
      id: lastName.id,
      type: 'text',
      required: true,
      validation: {
        required: 'Please enter last name',
      },
    },
    {
      label: email.label,
      id: email.id,
      type: 'email',
      required: true,
      validation: {
        required: 'Please enter an email address',
        pattern: {
          value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Invalid email pattern',
        },
      },
    },
    {
      label: phoneNumber.label,
      id: phoneNumber.id,
      type: 'text',
      required: true,
      validation: {
        required: 'Please enter a phone number for your guarantor',
        pattern: {
          value: /^\d+$/,
          message: 'Invalid phone number',
        },
      },
    },
  ],
};

export default householdFormDefinition;
