import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Footer from './components/molecules/Footer/Footer';
import Header from './components/molecules/Header/Header';
import Container from './components/atoms/Container/Container';
import Basket from './components/molecules/Basket/Basket';
import ProgressStepper from './components/atoms/ProgressStepper/ProgressStepper';
import { UserContext } from './providers/UserContext';

const AppLayout = ({ basket, children }) => {
  window.scrollTo(0, 0);

  const { isRenewal } = useContext(UserContext) || {};

  return (
    <>
      <Header />
      <Container>
        { !isRenewal && <ProgressStepper /> }
        { basket
        && (
        <div className="row flex-row-reverse ">
          <div className="mt-5 col-md-4">
            <Basket />
          </div>
          <div className="mt-5 col-md-8">
            { children }
          </div>
        </div>
        )}
        {!basket
        && (
        <div className="mt-5">
          { children }
        </div>
        )}
      </Container>
      <Footer
        social={[]}
      />
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  basket: PropTypes.bool,
};

AppLayout.defaultProps = {
  basket: false,
};

export default AppLayout;
