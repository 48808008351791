import { Storage } from 'aws-amplify';

export const getSignedKey = (key) => Storage.get(key);

export const getUnitImages = async (unitName) => {
  const files = await Storage.list(unitName);
  return Promise.all(files
    .filter((file) => file.key.match(/\.(jpg|png|gif|pdf)$/))
    .reduce((ac, { key }) => (key !== unitName ? ac.concat(key) : ac), [])
    .map(getSignedKey));
};
