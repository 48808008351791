import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import TitleHeader from '../TitleHeader/TitleHeader';
import RadioInput from '../../atoms/RadioInput/RadioInput';
import Periodicity from '../Periodicity/Periodicity';
import { tenancyTermCopy } from '../../../content/main.json';
import { formatBreakClause } from '../../../helpers/utils/generic';
import { WalesContext } from '../../../providers/WalesContext';

import './TenancyTerm.scss';

const TenancyTerm = ({
  terms,
  quoteIndex,
  unitIndex,
  setSelectedQuoteIndex,
  setChosenOfferId,
  setPeriodicity,
}) => {
  const { isWales } = useContext(WalesContext);
  let tenancyTerms = !isWales ? tenancyTermCopy : tenancyTermCopy.wales;
  const onClickOffer = (e) => {
    setChosenOfferId(e.target.value);
    setSelectedQuoteIndex(
      terms.findIndex((elem) => elem.id === e.target.value),
    );
  };

  const periodicityArray = () => (terms[quoteIndex].periodicity
    && terms[quoteIndex].periodicity.split(';'))
    || [];

  return (
    <div className="tenancyTerm" data-testid="tenancyTerm">
      <TitleHeader title={tenancyTerms.title} />


      <div className="tenancyTerm__info-block row">
        {tenancyTerms.information.map((info, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <p className="tenancyTerm__info-text col-lg-8" key={`tenancy-text-${i}`}>{info}</p>
        ))}
      </div>


      <div
        className="tenancyTerm__form-container row"
        onChange={(e) => onClickOffer(e)}
      >
        {terms.map((termObject) => (
          <div
            key={`offer-${termObject.id}-term`}
            className="tenancyTerm__term col-md-6 col-lg-4"
          >
            <RadioInput
              id={termObject.id}
              name={`radio-group-offer-${unitIndex}`}
              label={termObject.term}
              value={termObject.id}
            />
          </div>
        ))}
      </div>

      {quoteIndex !== null && (
        <div
          className="tenancyTerm__break-clause-block"
          data-testid="break-clause-block"
        >
          <TitleHeader title="Break clause" />
          <p className="tenancyTerm__break-clause">
            {!isWales ? formatBreakClause(terms[quoteIndex].breakClause) : 
              (formatBreakClause(terms[quoteIndex].breakClause) || '')
                .replace('tenancy','occupation contract')
                .replace('tenant','contract holder')
            }
          </p>
        </div>
      )}

      {quoteIndex !== null && (
        <Periodicity
          terms={periodicityArray()}
          setPeriodicity={setPeriodicity}
          quoteIndex={quoteIndex}
        />
      )}
    </div>
  );
};

TenancyTerm.propTypes = {
  terms: PropTypes.instanceOf(Array).isRequired,
  unitIndex: PropTypes.number.isRequired,
  quoteIndex: PropTypes.number,
  setSelectedQuoteIndex: PropTypes.func.isRequired,
  setChosenOfferId: PropTypes.func.isRequired,
  setPeriodicity: PropTypes.func.isRequired,
};

TenancyTerm.defaultProps = {
  quoteIndex: null,
};

export default TenancyTerm;
