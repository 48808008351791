import React from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import './Timer.scss';

const Timer = ({ offerExpiresAt, timerCompleted }) => {
  const renderer = ({
    // eslint-disable-next-line react/prop-types
    minutes, seconds, completed,
  }) => {
    if (completed) {
      return <p>{timerCompleted}</p>;
    }

    return (
      <p>
        {`${minutes}m ${seconds}s `}
        <span className="timer__text">left to make payment</span>
      </p>
    );
  };

  return (
    <div className="timer">
      <Countdown
        date={new Date(offerExpiresAt.replace('+0000', '+00:00'))}
        renderer={renderer}
      />
    </div>
  );
};

Timer.propTypes = {
  offerExpiresAt: PropTypes.string.isRequired,
  timerCompleted: PropTypes.string.isRequired,
};

export default Timer;
