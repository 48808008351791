
import React from 'react';
import './Header.scss';
import Menu from '../Menu/Menu';

export default function Header() {
  return (
    <div className="header">
      <Menu />
      <div className="header__title">
        Grainger
        <span>PLC</span>
      </div>
    </div>
  );
}
