import React from 'react';
import PropTypes from 'prop-types';

import './TitleHeader.scss';

const TitleHeader = ({
  title, category, large, className,
}) => {
  if (!title && !category) return null;

  return (
    <div className={`title-header ${className} ${large ? 'large' : ''}`}>
      {category && (
        <p
          className="title-header__category"
          data-testid={category.toLowerCase().replace(/\s/g, '-')}
        >
          {category}
        </p>
      )}
      {title && (
        <h2 className="title-header__title" data-testid="title-header">
          {title}
          {' '}
        </h2>
      )}
    </div>
  );
};

TitleHeader.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  className: PropTypes.string,
  large: PropTypes.bool,
};

TitleHeader.defaultProps = {
  title: '',
  category: '',
  className: '',
  large: false,
};

export default TitleHeader;
