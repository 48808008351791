import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carousel.scss';

const ImageCarousel = ({
  imageKeys,
  small,
}) => (
  <div data-testid="carousel">
    { imageKeys
      && (
      <Carousel showThumbs={!small}>
        {imageKeys.map((k) => (
          <img
            src={k}
            key={k}
            alt={k}
            width="400"
          />
        ))}
      </Carousel>
      )}
  </div>
);

ImageCarousel.propTypes = {
  imageKeys: PropTypes.instanceOf(Array).isRequired,
  small: PropTypes.bool,
};

ImageCarousel.defaultProps = {
  small: false,
};

export default ImageCarousel;
