import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const WalesContext = React.createContext({
  isWales: false,
  setIsWales: () => { },
});

const WalesProvider = ({ children }) => {
  const [isWales, setIsWales] = useState(null);

  return (
    <WalesContext.Provider value={{ isWales, setIsWales }}>
      {children}
    </WalesContext.Provider>
  );
};

WalesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WalesProvider;
