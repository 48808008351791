import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const ContactRoleContext = React.createContext();

const ContactRoleProvider = ({ children }) => {
  const [householdData, setHouseholdData] = useState({
    LeadTenant: [],
    Tenant: [],
    PermittedOccupier: [],
    Guarantor: [],
  });

  return (
    <ContactRoleContext.Provider value={{ householdData, setHouseholdData }}>
      {children}
    </ContactRoleContext.Provider>
  );
};

ContactRoleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContactRoleProvider;
