import React from 'react';
import PropTypes from 'prop-types';
import './ErrorBanner.scss';

const ErrorBanner = ({ title, body }) => (
  <div className="errorBanner" data-testid="errorBanner">
    {title && <h2 className="errorBanner__title">{title}</h2>}
    {body
      && body.map((text, index) => (
        <p
          key={`errorBanner-text-${index}`} // eslint-disable-line react/no-array-index-key
          className="errorBanner__text"
          dangerouslySetInnerHTML={{ __html: text }} // eslint-disable-line react/no-danger
        />
      ))}
  </div>
);

ErrorBanner.propTypes = {
  title: PropTypes.string,
  body: PropTypes.instanceOf(Array),
};

ErrorBanner.defaultProps = {
  title: null,
  body: null,
};
export default ErrorBanner;
