import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import {
  createContactAccount,
  updateContact,
  updateOpportunityContactRole,
} from '../../../api/salesforce';
import { PATHS } from '../../../navigationConstants';
import Button from '../../atoms/Button/Button';
import ErrorBanner from '../ErrorBanner/ErrorBanner';
import EmailInput from '../../atoms/EmailInput/EmailInput';
import SavedInput from '../../atoms/SavedInput/SavedInput';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import TextInput from '../../atoms/TextInput/TextInput';
import TitleHeader from '../TitleHeader/TitleHeader';
import Card from '../../atoms/Card/Card';
import './ContactRoleForm.scss';
import { ContactRoleContext } from '../../../providers/ContactRoleContext';
import { fieldAttributes } from '../../../content/main.json';
import { UserContext } from '../../../providers/UserContext';
import { WalesContext } from '../../../providers/WalesContext';

const ContactRoleForm = ({
  fields,
  extensionFields,
  setDisabled,
  title,
  role,
  index,
  isEditing,
  setIsEditing,
  appHistory,
}) => {
  const { register, errors, handleSubmit } = useForm();
  const [selectedValue, setSelectedValue] = useState(null);
  const [errorDetected, setErrorDetected] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);
  const [emailAlreadyInUseError, setEmailAlreadyInUseError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { householdData, setHouseholdData } = useContext(ContactRoleContext);
  const contact = householdData[role][index];
  const { opportunityId } = useContext(UserContext);
  const { isWales } = useContext(WalesContext);
  const tempArr = { ...householdData };
  const tempContact = tempArr[role][index];

  const onEdit = () => {
    setDisabled(true);
    setIsEditing(true);
    tempContact.isEditing = true;
    setHouseholdData(tempArr);
  };

  const onCancel = () => {
    setDisabled(false);
    if (contact.isEditing) {
      setIsEditing(false);
      tempContact.isEditing = false;
    } else {
      tempArr[role].splice(index, 1);
    }
    setHouseholdData(tempArr);
  };

  const onRemove = () => {
    tempArr[role].splice(index, 1);
    setHouseholdData(tempArr);
    updateOpportunityContactRole(contact.ocrId, {
      active: false,
      reasonForRemoval: 'Removed By Customer',
    });
  };

  const setUpdatedFields = (savedData, data) => {
    let newfieldObject; // this will be for any new fields added to permitted occupier

    const updatedFields = savedData
      && savedData.fields.reduce((acc, field) => {
        // first condition: checks if a savedData field doesn't exist in the updated data
        // so where a field has been removed from permitted occupier
        // second condition: checks if changes have been made to the value
        if (!data[field.id] || field.value !== data[field.id].trim()) {
          acc[field.id] = data[field.id] || null;
        }
        return acc;
      }, {});

    // here if a new field has been added,
    // updatedFields will now include that extra field and the newfieldObject is created
    if (Object.keys(data).length > savedData.fields.length) {
      const objectKeys = Object.keys(data);
      const newfield = objectKeys[objectKeys.length - 1];
      updatedFields[newfield] = data[newfield];

      newfieldObject = {
        id: newfield,
        label: fieldAttributes[newfield].label,
        value: data[newfield],
      };
    }

    return { updatedFields, newfieldObject };
  };

  // eslint-disable-next-line consistent-return
  const onSubmit = async (data) => {
    setDuplicateError(false);
    setEmailAlreadyInUseError(false);
    setErrorDetected(false);
    setIsSubmitting(true);
    if (contact && contact.isEditing) {
      setIsEditing(false);
      tempContact.isEditing = false;
      const { updatedFields, newfieldObject } = setUpdatedFields(contact, data);

      try {
        await updateContact(contact.contactId, contact.ocrId, updatedFields);
        // updates the context
        tempContact.fields.map((elem) => {
          if (elem.id in updatedFields) {
            // eslint-disable-next-line no-param-reassign
            elem.value = updatedFields[elem.id];
          }
          return null;
        });

        if (newfieldObject) tempContact.fields.push(newfieldObject);

        tempArr[role].splice(index, 1, tempContact);
        setHouseholdData(tempArr);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Could NOT update contact', error);
        return appHistory.push(PATHS.ERROR);
      }
    } else {
      try {
        const res = await createContactAccount(
          data,
          opportunityId,
          role,
        );
        if (!res.success || !res.result) throw res.error;
        if (res.success === true) {
          const mappedFields = Object.keys(data).map((key) => ({
            id: key,
            label: fieldAttributes[key].label,
            value: data[key],
          }));
          tempArr[role].splice(index, 1, {
            contactId:
              res.contactId,
            ocrId: res.ocrId,
            role,
            fields: mappedFields,
          });
          setHouseholdData(tempArr);
        } else {
          onCancel();
        }
        return setDisabled(false);
      } catch (error) {
        if (error && error === 'DUPLICATES_DETECTED') {
          // eslint-disable-next-line no-console
          console.error('Duplicates detected');
          setDuplicateError(true);
        } else if (error && error === 'ALREADY_IN_USE') {
          // eslint-disable-next-line no-console
          console.error('Email already in use');
          setEmailAlreadyInUseError(true);
        } else {
          // eslint-disable-next-line no-console
          console.error('Could NOT create new contact');
          setErrorDetected(true);
        }
      }
    }
    setIsSubmitting(false);
  };

  const inputTypeCheck = (item) => {
    switch (item) {
      case 'email':
        return EmailInput;
      case 'text':
        return TextInput;
      case 'select':
        return SelectInput;
      default:
        return TextInput;
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card>
        {errorDetected && (
          <ErrorBanner
            body={['Household member could not be saved.', 'Please try again or contact Grainger.']}
          />
        )}
        {duplicateError && (
          <ErrorBanner
            body={['This looks like a duplicate.', 'Please try again with unique email and phone numbers or contact Grainger.']}
          />
        )}
        {emailAlreadyInUseError && (
          <ErrorBanner
            body={[`This email address is already in use.', 'Please confirm the First and Last Names of the additional ${!isWales ? 'tenant' : 'contract holder'} or contact Grainger for assistance.`]}
          />
        )}
        <TitleHeader category={title} />
        {contact && Object.keys(contact).length && !contact.isEditing ? (
          <>
            <div className="contactRoleForm">
              {contact.fields.map((field) => (
                <React.Fragment key={uuid()}>
                  {field.value && (
                    <SavedInput
                      id={field.id}
                      label={field.label}
                      value={field.value}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
            <div className="contactRoleForm__buttonContainer">
              <Button
                type="button"
                title="Edit"
                onClick={onEdit}
                testId="editContactRole"
                className="contactRoleForm__button"
                plain
                disabled={isEditing}
              />
              <Button
                type="button"
                title="Remove"
                testId="removeContactRole"
                className="contactRoleForm__button"
                plain
                onClick={onRemove}
              />
            </div>
          </>
        ) : (
          <>
            <div
              className="ContactRoleForm row"
              data-testid="editingContactRoleForm"
            >
              {fields
                && fields.map((field, i) => {
                  const InputToRender = inputTypeCheck(field.type, i);
                  return (
                    <InputToRender
                      key={field.id}
                      label={field.label}
                      type={field.type}
                      setSelectedValue={setSelectedValue}
                      id={field.id}
                      value={
                        contact && contact.fields && contact.fields[i] && contact.fields[i].value
                      }
                      selectValue={
                        selectedValue
                        || (contact && contact.fields && contact.fields[i]
                        && contact.fields[i].value)
                      }
                      register={register}
                      validation={field.validation}
                      hasError={errors[field.id]}
                      className="col-lg-6"
                      testId={field.id}
                      options={field.options}
                    />
                  );
                })}
              {selectedValue
                && extensionFields.map((field) => {
                  if (selectedValue === field.selector) {
                    const InputToRender = inputTypeCheck(field.type);
                    return (
                      <InputToRender
                        key={field.id}
                        label={field.label}
                        type={field.type}
                        setSelectedValue={setSelectedValue}
                        id={field.id}
                        value={
                          (contact
                            && contact.fields
                            && contact.fields[fields.length]
                            && contact.fields[fields.length].id === field.id
                            && contact.fields[fields.length].value)
                          || null
                        }
                        register={register}
                        validation={field.validation}
                        hasError={errors[field.id]}
                        className="col-lg-6"
                        testId={field.id}
                        options={field.options}
                      />
                    );
                  }
                  return null;
                })}
            </div>
            <div className="contactRoleForm__buttonContainer">
              <Button
                type="button"
                title="Cancel"
                testId="cancelContactRole"
                className="contactRoleForm__button"
                plain
                onClick={onCancel}
              />
              <Button
                type="submit"
                title="Save"
                testId="saveContactRole"
                primary
                disabled={isSubmitting}
              />
            </div>
          </>
        )}
      </Card>
    </form>
  );
};

ContactRoleForm.propTypes = {
  fields: PropTypes.arrayOf(Object).isRequired,
  extensionFields: PropTypes.arrayOf(Object),
  role: PropTypes.string.isRequired,
  setDisabled: PropTypes.func,
  title: PropTypes.string,
  index: PropTypes.number.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  appHistory: PropTypes.instanceOf(Object),
};

ContactRoleForm.defaultProps = {
  extensionFields: [],
  setDisabled: () => {},
  title: '',
  appHistory: {},
};

export default ContactRoleForm;
