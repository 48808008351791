/* eslint-disable react/no-danger */
import React from 'react';
import xss from 'xss';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import { changeRequestConfirmed } from '../../content/main.json';

const { title, content } = changeRequestConfirmed;
const ChangeRequestConfirmed = () => (
  <>
    <TitleHeader title={title} large />
    <p dangerouslySetInnerHTML={{ __html: xss(content) }} />
  </>
);

export default ChangeRequestConfirmed;
