/* eslint-disable key-spacing */
/* eslint-disable camelcase */

import { SF_ROLES, VALID_ROLES } from '../appConstants';

export const transformStageName = (stageName) => (
  stageName.replace(/\s/g, '_').toUpperCase()
);

export const transformRoleToApi = (role) => SF_ROLES[role];
export const transformRoleFromApi = (role) => role.replace(/^\d*\s*/g, '');

export const transformHouseholdReferences = (result) => {
  const mappedArray = result
    .filter((person) => VALID_ROLES.includes(person.Role))
    .map((person) => ({
      role: transformRoleFromApi(person.Role),
      firstName: person.Contact.FirstName,
      lastName: person.Contact.LastName,
      referencingStatus: person.Reference_Check_Status__c,
      isReferencingExempt: person.Exempt_Referencing__c,
    }));

  return {
    householdReferencingOutcome: result[0].Opportunity.Application_Approval__c,
    householdMembers: mappedArray,
  };
};

export const transformReferencing = ({
  Reference_Check_Status__c,
  Reference_Application_Form_Url__c,
  Exempt_Referencing__c,
  Opportunity,
  access_token,
  error
}) => ({
  referencingStatus: Reference_Check_Status__c,
  referencingUrl: Reference_Application_Form_Url__c,
  isReferencingExempt: Exempt_Referencing__c,
  referenceProvider: Opportunity ? (Opportunity.Referencing_Provider__c || '') : '',
  access_token: access_token || '',
  error
});

export const transformFromApiQuoteWithUnit = ({
  Id,
  Term__c,
  Break_Clause__c,
  Discount,
  GrandTotal,
  Payment_Periodicity__c,
  Move_in_Start__c,
  Move_in_End__c,
}) => ({
  id: Id,
  term: Term__c,
  breakClause: Break_Clause__c,
  discount: Discount,
  grandTotal: GrandTotal,
  periodicity: Payment_Periodicity__c,
  moveInStart: Move_in_Start__c,
  moveInEnd: Move_in_End__c,
});

export const transformFromGetPrimaryOffer = ({
  Id,
  Term__c,
  Break_Clause__c,
  Wish_List__c,
  Primary_Quote__c,
  Unit__r,
  Opportunity,
} = {}) => ({
  id: Id,
  term: Term__c,
  breakClause: Break_Clause__c,
  wishList: Wish_List__c,
  primaryQuote: Primary_Quote__c,
  unit: Unit__r,
  opportunity: Opportunity,
});

export const transformFromGetOpportunity = ({
  Apportioned_Rent__c,
  Apportioned_Rent_Start__c,
  Apportioned_Rent_End__c,
  First_Month_Rental__c,
  First_Full_Month_Rent_Start__c,
  First_Full_Month_Rent_End__c,
  Concession__c,
  Security_Deposit__c,
  Advance_Payment__c,
  Advance_Payment_Exempt__c,
  Advance_Payment_Made__c,
  Offer_Expires_at__c,
  Move_in_Monies_Paid__c,
  Payment_Periodicity__c,
  Total__c,
  Total_Due__c,
  Less_Advance_Payment__c,
  First_DD_Collection__c,
  Last_DD_Collection__c,
}) => ({
  apportionedRent: Apportioned_Rent__c,
  apportionedRentStart: Apportioned_Rent_Start__c,
  apportionedRentEnd: Apportioned_Rent_End__c,
  firstMonthRental: First_Month_Rental__c,
  firstMonthRentalStart: First_Full_Month_Rent_Start__c,
  firstMonthRentalEnd: First_Full_Month_Rent_End__c,
  concession: Concession__c,
  securityDeposit: Security_Deposit__c,
  advancePayment: Advance_Payment__c,
  isAdvancePaymentExempt: Advance_Payment_Exempt__c,
  isAdvancePaymentPaid: Advance_Payment_Made__c,
  offerExpiresAt: Offer_Expires_at__c,
  moveInMoniesPaid: Move_in_Monies_Paid__c,
  paymentPeriodicity: Payment_Periodicity__c,
  total: Total__c,
  totalDue: Total_Due__c,
  lessAdvancePayment: Less_Advance_Payment__c,
  firstDDCollection: First_DD_Collection__c,
  lastDDCollection: Last_DD_Collection__c,
});

export const transformPayment = ({ Id, asp04__eCommerce_URL__c, asp04__Status__c }) => ({
  id: Id,
  iframeUrl: asp04__eCommerce_URL__c,
  status: asp04__Status__c,
});

export const mapQuotesByUnit = (quotes) => {
  const mappedQuotes = [];
  const presentedQuotes = quotes.filter((q) => q.Status === 'Presented');

  presentedQuotes.forEach((quote) => {
    const unitId = quote.Unit__r.Id;
    const unitObject = mappedQuotes.find((x) => (x.unitId === unitId));
    if (!unitObject) {
      mappedQuotes.push({
        opportunityId    : quote.OpportunityId,
        wishList         : quote.Wish_List__c,
        monthlyRent      : quote.TotalPrice,
        unitId           : quote.Unit__r.Id,
        unitName         : quote.Unit__r.Name,
        availableDate    : quote.Unit__r.Available_Date__c,
        estate           : quote.Unit__r.Estate__c && quote.Unit__r.Estate__c.replace(/<[^>]+>/g, ''),
        premium          : quote.Unit__r.Premium_Non_Premium__c,
        furniturePack    : quote.Unit__r.Furniture_Pack__c,
        bathrooms        : quote.Unit__r.Bathrooms__c,
        bedrooms         : quote.Unit__r.Bedrooms__c,
        petFriendly      : quote.Unit__r.Pet_Friendly__c,
        buildingAmenities: quote.Unit__r.Building_Amenities__c,
        floorLevel       : quote.Unit__r.Floor_Level__c,
        flatNo           : quote.Unit__r.Flat_No__c,
        floorType        : quote.Unit__r.Floor_Type__c,
        furnished        : quote.Unit__r.Furnished__c,
        unitAmenities    : quote.Unit__r.Unit_Amenities__c ? quote.Unit__r.Unit_Amenities__c.split(';') : [],
        unitStatus       : quote.Unit__r.Unit_Status__c,
        outdoorSpace     : quote.Unit__r.Outdoor_Space__c,
        internalSpaceFt  : quote.Unit__r.Internal_Space_GIA_ft__c,
        keyFeatures      : quote.Unit__r.Key_Features__c,
        streetNo         : quote.Unit__r.Street_No__c,
        streetName       : quote.Unit__r.Street_Name__c,
        postCode         : quote.Unit__r.Post_Code__c,
        city             : quote.Unit__r.City__c,
        county           : quote.Unit__r.County__c,
        aspect           : quote.Unit__r.Aspect__c,
        accessibility    : quote.Unit__r.Accessibility__c,
        moveInStart      : quote.Move_in_Start__c,
        moveInEnd        : quote.Move_in_End__c,
        imageUrl         : quote.Unit__r.Image_URL__c,
        isWales          : quote.isWales,
        quotes           : [transformFromApiQuoteWithUnit(quote)],
      });
    } else {
      unitObject.quotes.push(transformFromApiQuoteWithUnit(quote));
    }
  });

  return mappedQuotes;
};

export const transformUnitFromApi = (unit) => ({
  id                        : unit.Id,
  isDeleted                 : unit.IsDeleted,
  name                      : unit.Name,
  building                  : unit.Building__c,
  availableDate             : unit.Available_Date__c,
  ERV                       : unit.ERV__c,
  estate                    : unit.Estate__c,
  furniturePack             : unit.Furniture_Pack__c,
  petFriendly               : unit.Pet_Friendly__c,
  premiumNonPremium         : unit.Premium_Non_Premium__c,
  unFurnishedUnit           : unit.Un_Furnished_Unit__c,
  unitClassification        : unit.Unit_Classification__c,
  unitStatus                : unit.Unit_Status__c,
  notAvailableToViewReason  : unit.Not_Available_to_View_Reason__c,
  floorLevel                : unit.Floor_Level__c,
  bedrooms                  : unit.Bedrooms__c,
  accessibility             : unit.Accessibility__c,
  bathrooms                 : unit.Bathrooms__c,
  buildingAmenities         : unit.Building_Amenities__c,
  distanceToTubeTrainTram   : unit.Distance_to_Tube_Train_Tram__c,
  flatNo                    : unit.Flat_No__c,
  floorType                 : unit.Floor_Type__c,
  floorplanType             : unit.Floorplan_Type__c,
  furnished                 : unit.Furnished__c,
  futureVacantDate          : unit.Future_Vacant_Date__c,
  maxOccupancy              : unit.Max_Occupancy__c,
  postCode                  : unit.Post_Code__c,
  propertyType              : unit.Property_Type__c,
  streetName                : unit.Street_Name__c,
  streetNo                  : unit.Street_No__c,
  unitAmenities             : unit.Unit_Amenities__c,
  View                      : unit.View__c,
  outdoorSpace              : unit.Outdoor_Space__c,
});

export const activeOCRFromApi = ({
  Id,
  OpportunityId,
  Contact_First_Name__c,
  Role,
  Opportunity: {
    StageName,
    Tenancy_Type__c,
    Agreement_Type__c,
    Payment_Periodicity__c,
  } = {},
} = {}) => ({
  ocrId: Id,
  role: Role,
  opportunityId: OpportunityId,
  opportunityStage: StageName && StageName.replace(/\s/g, '_').toUpperCase(),
  firstName: Contact_First_Name__c,
  tenancyType: Tenancy_Type__c,
  agreementType: Agreement_Type__c,
  paymentPeriodicity: Payment_Periodicity__c,
});

export const mapNames = ({
  Contact_Full_Name__c,
}) => (Contact_Full_Name__c);

export const mapRenewalQuotes = (quotes) => quotes.filter((q) => q.Status === 'Presented').map(({
  Id,
  Term__c,
  TotalPrice,
  Payment_Periodicity__c,
  Break_Clause__c,
  Unit__r: {
    Id: unitId,
    Estate__c,
    Unit_Status__c,
    Name,
    Image_URL__c,
  },
  Opportunity: {
    Move_In_Date__c,
  },
  isWales
}) => ({
  offerId: Id,
  term: Term__c,
  monthlyRent: TotalPrice,
  paymentPeriodicity: Payment_Periodicity__c,
  breakClause: Break_Clause__c,
  unitStatus: Unit_Status__c,
  estate: Estate__c.replace(/<[^>]+>/g, ''),
  unitId,
  unitName: Name,
  imageUrl: Image_URL__c,
  moveInDate: Move_In_Date__c,
  isWales
}));
