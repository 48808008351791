import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import Summary from '../../components/molecules/Summary/Summary';
import Button from '../../components/atoms/Button/Button';
import { PATHS } from '../../navigationConstants';
import { householdSummaryCopy } from '../../content/main.json';
import { WalesContext } from '../../providers/WalesContext';


const RenewalSummary = ({ confirm, submitting }) => {
  const { isWales } = useContext(WalesContext);
  let houseHoldSummaryTerm = !isWales ? householdSummaryCopy : householdSummaryCopy.wales;
  return (
    <>
      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={{ __html: houseHoldSummaryTerm.renewalTenancyIntro }} />
      <Summary />

      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={{ __html: houseHoldSummaryTerm.renewalConfirm }} />
      <br />
      <div className="householdSummary__buttons">
        <Link to={PATHS.BUILD_RENEWAL}>
          <Button
            testId="renewal-back"
            title="Back"
            secondary
          />
        </Link>
        <Button
          testId="confirm-household-renewal"
          title={houseHoldSummaryTerm.button}
          onClick={confirm}
          disabled={submitting}
        />
      </div>
    </>
  );
}

RenewalSummary.propTypes = {
  confirm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default RenewalSummary;
