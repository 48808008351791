import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function useBackController(routes) {
  const history = useHistory();

  useEffect(() => {
    let currentPathname = null;
    let currentSearch = null;

    const unlisten = history.listen((newLocation, action) => {
      if (action === 'PUSH') {
        if (
          newLocation.pathname !== currentPathname
          || newLocation.search !== currentSearch
        ) {
          // Save new location
          currentPathname = newLocation.pathname;
          currentSearch = newLocation.search;

          const matchingRoute = routes.find(
            (route) => route.path.split('/:')[0] === newLocation.pathname,
          );

          if (matchingRoute && matchingRoute.back === false) {
            // Clone location object and push it to history
            history.push({
              pathname: newLocation.pathname,
              search: newLocation.search,
            });
          }
        }
      } else {
        // Send user back if they try to navigate back
        // eslint-disable-next-line no-lonely-if
        if (
          newLocation.pathname === currentPathname
          && newLocation.search === currentSearch
        ) {
          currentPathname = null;
          currentSearch = null;
          history.go(1);
          return;
        }
        // Save new location
        currentPathname = newLocation.pathname;
        currentSearch = newLocation.search;
      }
    });
    return () => {
      unlisten();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
