import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import { ASPERATO_STATUSES, PAYMENT_SUB_TYPE } from '../../appConstants';
import { PATHS } from '../../navigationConstants';
import Button from '../../components/atoms/Button/Button';
import Timer from '../../components/atoms/Timer/Timer';
import PaymentSummary from '../../components/atoms/PaymentSummary/PaymentSummary';
import AsperatoIFrame from '../../components/molecules/AsperatoIFrame/AsperatoIFrame';
import Loading from '../../components/atoms/Loading/Loading';
import { advancePaymentCopy } from '../../content/main.json';
import {
  getOpportunity,
  getOpportunityPayments,
  getPaymentRecord,
} from '../../api/salesforce';
import { extractAsperatoIframeURL } from '../../helpers/utils/generic';
import { UserContext } from '../../providers/UserContext';
import { PaymentContext } from '../../providers/PaymentContext';
import { WalesContext } from '../../providers/WalesContext';
import './AdvancePayment.scss';

const AdvancePayment = ({ history }) => {
  const [opportunityData, setOpportunityData] = useState({});
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState(null);
  const { opportunityId } = useContext(UserContext);
  const { paymentId } = useContext(PaymentContext);
  const { isWales } = useContext(WalesContext);
  const {
    isAdvancePaymentExempt,
    isAdvancePaymentPaid,
    offerExpiresAt,
  } = opportunityData;
  let advancePaymentTerms = !isWales ? advancePaymentCopy : advancePaymentCopy.wales;
  const {
    title,
    category,
    info,
    iframeMessage,
    timerCompleted,
    paymentDue,
    nextButton,
  } = advancePaymentTerms;

  const asperatoListener = (e) => {
    if (url && url.includes(e.origin)) {
      switch (e.data) {
        case ASPERATO_STATUSES.SUCCESS:
          history.push(PATHS.ADVANCE_PAYMENT_SUCCESS);
          break;
        case ASPERATO_STATUSES.ERROR:
          history.push(PATHS.ERROR_PAYMENT_FAILED);
          break;
        case ASPERATO_STATUSES.CANCEL:
          history.push(PATHS.ERROR_PAYMENT_FAILED);
          break;
        default:
          if (
            e.data.includes(ASPERATO_STATUSES.VALUE)
            && e.data.toLowerCase().includes('timeout')
          ) {
            history.push(PATHS.ERROR_PAYMENT_TIMEOUT);
          }
      }
    }
  };

  window.addEventListener('message', asperatoListener);

  useEffect(() => {
    async function fetchData() {
      const data = await getOpportunity(opportunityId);
      setOpportunityData(data);

      if (!data.isAdvancePaymentExempt && !data.isAdvancePaymentPaid) {
        let result;
        if (paymentId) {
          result = await getPaymentRecord(paymentId);
        } else {
          result = await getOpportunityPayments(
            opportunityId,
            PAYMENT_SUB_TYPE.ADVANCED,
          );
        }
        const { iframeUrl } = result;
        setUrl(extractAsperatoIframeURL(iframeUrl));
      }
      setLoading(false);
    }
    fetchData();
  }, [opportunityId, paymentId]);

  return (
    <>
      <TitleHeader title={title} category={category} large />
      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={{ __html: info }} />
      {loading && <Loading />}

      <div className="advancePayment__banner">
        {paymentDue}
        <p className="advancePayment__amount">{`£${opportunityData.advancePayment}`}</p>
      </div>
      {!loading && (
        <>
          <PaymentSummary {...opportunityData} />
          {!isAdvancePaymentExempt && !isAdvancePaymentPaid && (
            <>
              <Timer
                offerExpiresAt={offerExpiresAt}
                timerCompleted={timerCompleted}
              />
              <AsperatoIFrame
                src={url}
                iframeLoadingMessage={iframeMessage}
                appHistory={history}
                sucessPath={PATHS.ADVANCE_PAYMENT_SUCCESS}
              />
            </>
          )}
        </>
      )}
      {(isAdvancePaymentExempt || isAdvancePaymentPaid) && (
        <Link to={PATHS.ADVANCE_PAYMENT_SUCCESS}>
          <Button title={nextButton} testId="advance-next-button" />
        </Link>
      )}
    </>
  );
};

AdvancePayment.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default AdvancePayment;
