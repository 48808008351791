import { fieldAttributes } from '../content/main.json';

const { amount } = fieldAttributes;

const directDebitFormDefinition = {
  label: amount.label,
  id: amount.id,
  type: 'number',
  disabled: false,
  required: true,
  validation: {
    required: 'Please enter an amount',
  },
  onBlurMessage: 'Please save amount',
};

export default directDebitFormDefinition;
