import { fieldAttributes } from '../../content/main.json';
import { VALID_ROLES } from '../../appConstants';

const transformGetHouseholdSummary = (data) => {
  const mappedResult = [];

  data
    .filter((contact) => VALID_ROLES.includes(contact.Role))
    .forEach((member) => {
      const fields = [
        {
          id: fieldAttributes.firstName.id,
          label: fieldAttributes.firstName.label,
          value: member.Contact.FirstName,
        },
        {
          id: fieldAttributes.lastName.id,
          label: fieldAttributes.lastName.label,
          value: member.Contact.LastName,
        },
        {
          id: fieldAttributes.relationship.id,
          label: fieldAttributes.relationship.label,
          value: member.Relationship__c,
        },
        {
          id: fieldAttributes.email.id,
          label: fieldAttributes.email.label,
          value: member.Contact.Email,
        },
        {
          id: fieldAttributes.phoneNumber.id,
          label: fieldAttributes.phoneNumber.label,
          value: member.Contact.Phone,
        },
        {
          id: fieldAttributes.dob.id,
          label: fieldAttributes.dob.label,
          value: member.Contact.Birthdate,
        },
        {
          id: fieldAttributes.other.id,
          label: fieldAttributes.other.label,
          value: member.Other__c,
        },
      ].filter((dt) => dt.value);

      mappedResult.push({
        contactId: member.ContactId,
        ocrId: member.Id,
        role: member.Role.replace(/^\d*|\s*/g, ''),
        fields,
      });
    });

  return mappedResult;
};

export default transformGetHouseholdSummary;
