import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './AsperatoIFrame.scss';
import { ASPERATO_STATUSES } from '../../../appConstants';
import { PATHS } from '../../../navigationConstants';

const AsperatoIFrame = ({
  src,
  iframeLoadingMessage,
  className,
  sucessPath,
  appHistory,
}) => {
  const asperatoListener = (e) => {
    if (src && src.includes(e.origin)) {
      switch (e.data) {
        case ASPERATO_STATUSES.SUCCESS:
          appHistory.push(sucessPath);
          break;
        case ASPERATO_STATUSES.ERROR:
          appHistory.push(PATHS.ERROR_PAYMENT_FAILED);
          break;
        case ASPERATO_STATUSES.CANCEL:
          appHistory.push(PATHS.ERROR_PAYMENT_FAILED);
          break;
        default:
          if (
            e.data.includes(ASPERATO_STATUSES.VALUE)
            && e.data.toLowerCase().includes('timeout')
          ) {
            appHistory.push(PATHS.ERROR_PAYMENT_TIMEOUT);
          }
      }
    }
  };

  window.addEventListener('message', asperatoListener);

  const [loading, setLoading] = useState(true);
  return (
    <div
      className={`row asperato-iframe__container ${className || ''}`}
      data-testid="asperato-iframe"
    >
      {loading && (
        <h1 className="asperato-iframe__loading">{iframeLoadingMessage}</h1>
      )}
      <iframe
        className="col-12 asperato-iframe__iframe"
        title="payment-iframe"
        frameBorder="0"
        width="100%"
        src={src}
        onLoad={() => setLoading(false)}
      />
    </div>
  );
};

AsperatoIFrame.propTypes = {
  src: PropTypes.string.isRequired,
  iframeLoadingMessage: PropTypes.string,
  className: PropTypes.string,
  sucessPath: PropTypes.string.isRequired,
  appHistory: PropTypes.shape().isRequired,
};

AsperatoIFrame.defaultProps = {
  iframeLoadingMessage: '',
  className: '',
};

export default AsperatoIFrame;
