import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const PaymentContext = React.createContext({
  paymentId: '',
  setPaymentId: () => {},
});

const PaymentProvider = ({ children }) => {
  const [paymentId, setPaymentId] = useState(null);

  return (
    <PaymentContext.Provider value={{ paymentId, setPaymentId }}>
      {children}
    </PaymentContext.Provider>
  );
};

PaymentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PaymentProvider;
