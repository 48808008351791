import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import './SelectInput.scss';

const SelectInput = ({
  label, id, required, setSelectedValue, hasError, validation, testId,
  register, className, options, selectValue,
}) => {
  if (selectValue) {
    setSelectedValue(selectValue);
  }
  return (
    <div className={`selectInput ${className}`}>
      {label && <label className="selectInput__label" htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>}
      <select
        className={`selectInput__input ${hasError ? 'selectInput__input--error' : ''}`}
        onChange={(e) => setSelectedValue(e.target.value)}
        name={id}
        id={id}
        ref={register(validation)}
        required={required}
        data-testid={testId}
        value={selectValue}
      >
        <option> </option>
        {
          options && options.map((item) => (
            <option key={uuid()} value={item.value}>
              {item.value}
            </option>
          ))
        }
      </select>
      {
        hasError
        && <span className="selectInput__error-message" data-testid="test-error">{hasError.message}</span>
      }
    </div>
  );
};

SelectInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  testId: PropTypes.string,
  required: PropTypes.bool,
  register: PropTypes.func,
  setSelectedValue: PropTypes.func,
  validation: PropTypes.instanceOf(Object),
  hasError: PropTypes.instanceOf(Object),
  options: PropTypes.arrayOf(String),
  selectValue: PropTypes.string,
};

SelectInput.defaultProps = {
  selectValue: ' ',
  className: '',
  label: '',
  id: 'select',
  testId: '',
  required: false,
  register: () => { },
  setSelectedValue: () => { },
  validation: {},
  hasError: null,
  options: [],
};
export default SelectInput;
