/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';

import './Button.scss';

const Button = ({
  title,
  type,
  secondary,
  tertiary,
  plain,
  className,
  onClick,
  disabled,
  testId,
}) => (title
  && (
    <button
      type={type}
      onClick={onClick}
      className={`button ${secondary ? 'button--secondary' : ''}
      ${tertiary ? 'button--tertiary' : ''}
      ${plain ? 'button--plain' : ''}
      ${className}`}
      disabled={disabled}
      data-testid={testId}
    >
      {title}
    </button>
  )
);

Button.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  plain: PropTypes.bool,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};

Button.defaultProps = {
  title: null,
  type: null,
  onClick: null,
  className: '',
  secondary: false,
  tertiary: false,
  plain: false,
  disabled: false,
  testId: null,
};

export default Button;
